import { mergeStyles, MessageBar, MessageBarType } from "@fluentui/react";

interface Props {
  text: string;
}

const styles = mergeStyles({
    marginTop: "0.75rem",
})

export const InfoBar = ({ text }: Props) => (
  <MessageBar className={styles} messageBarType={MessageBarType.info} isMultiline={false}>
    {text}
  </MessageBar>
);
