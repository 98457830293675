import { mergeStyleSets } from "@fluentui/react";

export const issueListStyles = mergeStyleSets({
  exampleChildClass: {
    display: "block",
    marginBottom: "10px",
  },
  textFieldStyles: {
    maxWidth: "300px",
  },
  enumerationChip: {
    borderRadius: "50px",
    padding: "3px 10px",
  },
  siteLayout: {
    margin: "0 2cm",
  },
  detailsListContainer: {
    overflow: "auto",
    position: "absolute",
    inset: "0cm",
    bottom: "0px",
    top: "200px"
  },
  detailsList: {
    overflow: "visible",
    padding: "0 2cm 10cm 2cm"
  }
});

export const issueListEnumerationStylings: { [key: string]: React.CSSProperties } = {
  // Priorities
    High: {
      color: "#FFFFFF",
      backgroundColor: "#A4262C",
    },
    Medium: {
      color: "#FFFFFF",
      backgroundColor: "#CA5010",
    },
    Low: {
      color: "#8F6200",
      backgroundColor: "#FFEBC0",
    },
    None: {
      color: "#666666",
      backgroundColor: "#E5E5E5",
    },
    // Issue Types
    "Risk / Chance": {
      color: "#FFFFFF",
      backgroundColor: "#D13438",
    },
    Change: {
      color: "#8F6200",
      backgroundColor: "#FFEBC0",
    },
    Complaint: {
      color: "#A4262C",
      backgroundColor: "#FABBC3"
    },
    Information: {
      color: "#0068B8",
      backgroundColor: "#D4E7F6"
    },
    // Status
    Open: {
      color: "#A4262C",
      backgroundColor: "#FABBC3",
    },
    "In progress": {
      color: "#0068B8",
      backgroundColor: "#D4E7F6",
    },
    Closed: {
      color: "#666666",
      backgroundColor: "#E5E5E5",
    },
  };