const getChannelMessageContent = (issueName: string, assigneeDisplayName: string, reporterDisplayName: string, issueListLink: string) => `
<h2>New Issue - ${issueName}</h2>
Dear <at id="0">${assigneeDisplayName}</at><br/>
<br/>
You have been assigned a new task in the IssueLog of this project<br/>
<br/>
<a href="${issueListLink}">${issueListLink}</a><br/>
<br/>
Greetings<br/>
${reporterDisplayName}
`

export const getChannelMessagePayload = (issueName: string, assigneeDisplayName: string, assigneeUserId: string, reporterDisplayName: string, issueListLink: string) => ({
    "body": {
      "contentType": "html",
      "content": getChannelMessageContent(issueName, assigneeDisplayName, reporterDisplayName, issueListLink)
    },
    "mentions": [
      {
        "id": 0,
        "mentionText": assigneeDisplayName,
        "mentioned": {
          "user": {
            "displayName": assigneeDisplayName,
            "id": assigneeUserId,
            "userIdentityType": "aadUser"
          }
        }
      }
    ]
  })