import { css, mergeStyleSets } from "@fluentui/react";
import {
  IDynamicPerson,
  MgtPeople,
  PeoplePicker,
  PeoplePickerProps,
} from "@microsoft/mgt-react";
import React from "react";

const styles = mergeStyleSets({
  description: {
    color: "rgb(96, 94, 92)",
    fontSize: "10px",
    marginTop: "5px !important",
  },
  label: {
    fontSize: "14px",
    fontWeight: "600",
    color: "rgb(50, 49, 48)",
    boxSizing: "border-box",
    boxShadow: "none",
    margin: "0px",
    display: "block",
    padding: "5px 0px",
    overflowWrap: "break-word",
  },
  required: {
    color: "rgb(164, 38, 44)",
    paddingRight: "12px",
  },
});

interface Props extends Omit<PeoplePickerProps, "selectionChanged"> {
  label?: string;
  description?: string;
  required?: boolean;
  onSelectPeople: (people: IDynamicPerson[]) => void;
  selectionMode: "single" | "multiple";
  className?: string;
}

export const ExtendedPeoplePicker = (props: Props) => {
  const handleSelection = (event: any) => {
    props.onSelectPeople(event?.target?.selectedPeople);
  };

  return (
    <div className={props.className}>
      {props.label &&
        <label className={css("ms-Label", styles.label)}>
          {props.label}
          {props.required && <span className={styles.required}> *</span>}
        </label>
      }
      <PeoplePicker {...props} selectionChanged={handleSelection} />
      <span className={css("ms-TextField-description", styles.description)}>
        {props.description}
      </span>
    </div>
  );
};
