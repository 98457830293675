import { mergeStyleSets } from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";

export const qualityGatesModalStyles = mergeStyleSets({
  modalRoot: {
    display: "flex",
    width: "65%",
    minHeight: "16rem",
    padding: "1rem",
  },
  scrollableContent: {
    display: "flex",
    flexFlow: "column",
  },
  areaSeperator: {
    color: "grey",
    widht: "calc(100%-2rem)",
    margin: "0.75rem 0",
  },
  modalMainContent: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "12rem 1fr",
    gap: "8px",
  },
  modalFooter: {},
  modalFooterButtonContainer: {
    display: "flex",
    gap: "1rem",
    justifyContent: "right",
    marginLeft: "auto"
  },
  modalFooterBottomContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  modalParameterSeperator: {
    borderTop: "5px solid #a9a9a9",
    borderRadius: "10px",
    gridColumn: "1 / -1",
    width: "calc(100% - 4px)",
  },
  fullModelParameterWidth: {
    gridColumn: "1 / -1",
  },
  deleteButton: {
    backgroundColor: SharedColors.red10,
    borderColor: SharedColors.red10,
    ":hover": {
      backgroundColor: SharedColors.red20,
      borderColor: SharedColors.red20,
    },
  },
});
