import { DefaultButton, Modal, PrimaryButton, Shimmer } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { qualityGatesModalStyles } from "./QualityGatesModal.styles";
import { renderParameterComponent } from "./renderParameterComponent";
import { useQualityGatesData } from "./useQualityGateRenderableParameter";
import {
  ModalFormElement,
  QualityGatesModalProps,
} from "./QualityGatesModal.types";
import { useSWRConfig } from "swr";
import { useQualityGatesParameters } from "./useQualityGatesParameters";
import { useDynamicQualityGatesCreate } from "./useDynamicQualityGatesCreate";
import { useQualityGate } from "./hooks/useQualityGate";
import { useQualityGateType } from "./hooks/useQualityGateType";
import {
  QG_PARAMETER_DELIVERABLE_CATEGORIE_ID,
  QG_PARAMETER_TYPE_CHECKBOX_NAME,
  QG_PARAMETER_TYPE_JSONTEXT_NAME,
  QG_PARAMETER_TYPE_SEPERATOR_NAME,
  QG_TYPE_DELIVERABLE_ID,
} from "../../common/constants";
import { useQualityGatesDelete } from "./useQualityGatesDelete";

export const QualityGatesModal = ({ isNew }: QualityGatesModalProps) => {
  const { projectKey, qualityGateId, qualityGateTypeId } = useParams();
  // Data about the quality gate itself. We only need this for the typeId
  // if it is not already given.
  const { data: qualityGateData } = useQualityGate(
    qualityGateId ? parseInt(qualityGateId) : undefined
  );

  // If this is a new quality gate then we already have the typeid
  const currentQualityGateTypeId = isNew
    ? Number(qualityGateTypeId)
    : qualityGateData?.typeId;
  const { data: qualityGateTypeData } = useQualityGateType(
    currentQualityGateTypeId
  );

  // Current form data that is passed to render
  const [currentFormData, setCurrentFormData] = useState<
    ModalFormElement[] | undefined
  >();

  // === Sources of data needed to render the individual inputs
  const { data: newParametersToInitialize } = useQualityGatesParameters(
    isNew ? qualityGateTypeId : undefined
  );
  const { data: currentRawParametersData, saveData } = useQualityGatesData(
    projectKey!,
    qualityGateId!
  );

  // this effect syncs the new data the first time into currentFormData
  useEffect(() => {
    // We do not want to modify current form data if we already have it
    if (currentFormData) return;

    if (newParametersToInitialize) {
      setCurrentFormData(
        newParametersToInitialize
          .map((parameter) => ({
            dataId: undefined,
            // This field is already there but it will not be renderd and
            // maby in the future it will be diffent for now this is
            // a hardcoded value and always 0
            dataValue:
              parameter.id === QG_PARAMETER_DELIVERABLE_CATEGORIE_ID ? "0" : "",
            parameterId: parameter.id,
            parameterTableTypeId: parameter.typeId,
            parameterIsActive: parameter.isActive,
            parameterType: parameter.parameterType,
            parameterName: parameter.parameterName,
            parameterRequired: parameter.parameterRequired,
            parameterOrder: parameter.parameterOrder,
            parameterInline: parameter.parameterInline,
          }))
          .sort((a, b) => a.parameterOrder - b.parameterOrder)
          .filter((a) => a.parameterIsActive)
      );
      return;
    }

    if (currentRawParametersData) {
      setCurrentFormData(
        currentRawParametersData
          .map((qgParameterData) => ({
            dataId: qgParameterData.id,
            dataValue: qgParameterData.data,
            parameterId: qgParameterData.parameter.id,
            parameterTableTypeId: qgParameterData.parameter.typeId,
            parameterIsActive: qgParameterData.parameter.isActive,
            parameterType: qgParameterData.parameter.parameterType,
            parameterName: qgParameterData.parameter.parameterName,
            parameterRequired: qgParameterData.parameter.parameterRequired,
            parameterOrder: qgParameterData.parameter.parameterOrder,
            parameterInline: qgParameterData.parameter.parameterInline,
          }))
          .sort((a, b) => a.parameterOrder - b.parameterOrder)
          .filter((a) => a.parameterIsActive)
      );
      return;
    }
  }, [newParametersToInitialize, currentRawParametersData, currentFormData]);

  // === Buttons logic
  const navigate = useNavigate();
  const createQualityGate = useDynamicQualityGatesCreate();
  const deleteQualityGate = useQualityGatesDelete();

  // Dismiss button
  const handleDismiss = () => {
    navigate(`/qualitygates/${projectKey}`);
  };

  const { mutate } = useSWRConfig();
  // Delete Button
  const handleDeleteClick = () => {
    let id = parseInt(qualityGateId!);
    if (isNaN(id)) {
      return;
    }

    deleteQualityGate(id!).then(() => {
      // Await deletion than refresh
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const qualityGatesUrl = `${apiBaseUrl}QualityGates?`;
      mutate(
        (key: unknown) => {
          return (
            Array.isArray(key) &&
            key.length > 0 &&
            key[0].startsWith(qualityGatesUrl)
          );
        },
        (oldData: unknown) => oldData,
        { revalidate: true }
      );
    });
    navigate(`/qualitygates/${projectKey}`);
  };


  // Save button
  const handleSave = () => {
    if (isNew) {
      // Save new object logic
      if (!qualityGateTypeId)
        throw new Error("Quality gate type id is undefiend");
      if (!projectKey) throw new Error("No project key given");

      const initialCreationData = currentFormData?.reduce(
        (prev, modalFieldData) => {
          return {
            ...prev,
            [modalFieldData.parameterId]: modalFieldData.dataValue,
          };
        },
        {}
      );

      // Only do stuff if this is not undefined
      if (!initialCreationData) return;

      createQualityGate(
        qualityGateTypeId,
        projectKey,
        initialCreationData
      ).then(() => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const qualityGatesUrl = `${apiBaseUrl}QualityGates?`;
        mutate(
          (key: unknown) => {
            return (
              Array.isArray(key) &&
              key.length > 0 &&
              key[0].startsWith(qualityGatesUrl)
            );
          },
          (oldData: unknown) => oldData,
          { revalidate: true }
        );
      });
    } else {
      // Save already existing object
      if (!currentFormData) return;
      const promises = currentFormData.map((modalFieldData) => {
        return saveData({
          id: Number(modalFieldData.dataId),
          data: modalFieldData.dataValue,
        });
      });

      Promise.allSettled(promises).then(() => {
        // This will mutate all quality gates that are fetched and
        // revalidate them
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const qualityGatesUrl = `${apiBaseUrl}QualityGates?`;
        mutate(
          (key: unknown) => {
            return (
              Array.isArray(key) &&
              key.length > 0 &&
              key[0].startsWith(qualityGatesUrl)
            );
          },
          (oldData: unknown) => oldData,
          { revalidate: true }
        );

        // This mutates the old QualityGates Parameters and Data so that
        // the rendering does start from zero again on the first open
        const qualityGatesOtherUrls = [
          `${apiBaseUrl}QualityGatesData`,
          `${apiBaseUrl}QualityGatesParameters`,
        ];
        mutate(
          (key: unknown) => {
            return (
              Array.isArray(key) &&
              key.length > 0 &&
              (key[0].startsWith(qualityGatesOtherUrls[0]) ||
                key[0].startsWith(qualityGatesOtherUrls[1]))
            );
          },
          undefined,
          { revalidate: false }
        );
      });
    }

    navigate(`/qualitygates/${projectKey}`);
  };

  // Parameter updates
  const handleParameterUpdate = (
    modalFieldData: ModalFormElement,
    value?: string
  ) => {
    // We only update the specific data that is modified and return a new object
    // for it
    setCurrentFormData((data) =>
      data?.map((dataElement) => {
        if (dataElement.parameterId !== modalFieldData.parameterId)
          return dataElement;

        return {
          ...dataElement,
          dataValue: value ?? "",
        };
      })
    );
  };

  const isSaveDisabled = (() => {
    const canSave = currentFormData?.every((modalFormElement) => {
      // JsonTxt should not be saveable
      if (modalFormElement.parameterType === QG_PARAMETER_TYPE_JSONTEXT_NAME)
        return false;

      // Skip seperator
      if (modalFormElement.parameterType === QG_PARAMETER_TYPE_SEPERATOR_NAME)
        return true;
      // Skip Category
      if (
        modalFormElement.parameterId === QG_PARAMETER_DELIVERABLE_CATEGORIE_ID
      )
        return true;

      const isRequired = modalFormElement.parameterRequired;

      // We do not care if it is set if the value is not required
      if (!isRequired) return true;

      const isSet = Boolean(modalFormElement.dataValue);
      return isSet;
    });

    if (!canSave) {
      return true;
    }

    return false;
  })();

  return (
    <Modal
      containerClassName={qualityGatesModalStyles.modalRoot}
      isBlocking={true}
      isOpen={true}
      onDismiss={handleDismiss}
      scrollableContentClassName={qualityGatesModalStyles.scrollableContent}
    >
      <QualityGatesModalHeader
        isLoading={qualityGateTypeData == null}
        titel={
          currentFormData?.find(
            (val) => val.parameterName === "Deliverable Name"
          )?.dataValue
        }
        typeName={qualityGateTypeData?.name}
      />
      <hr className={qualityGatesModalStyles.areaSeperator} />

      <div className={qualityGatesModalStyles.modalMainContent}>
        {currentFormData ? (
          currentFormData?.map((modalFormElement) =>
            renderParameterComponent(modalFormElement, handleParameterUpdate)
          )
        ) : (
          <Shimmer />
        )}
      </div>

      <div className={qualityGatesModalStyles.modalFooter}>
        <hr className={qualityGatesModalStyles.areaSeperator} />
        <div className={qualityGatesModalStyles.modalFooterBottomContainer}>
          {currentQualityGateTypeId === QG_TYPE_DELIVERABLE_ID 
          && !isNew && 
          <PrimaryButton
            text="Delete"
            iconProps={{ iconName: "Delete" }}
            className={qualityGatesModalStyles.deleteButton}
            onClick={handleDeleteClick}
          />}
          <div className={qualityGatesModalStyles.modalFooterButtonContainer}>
            <PrimaryButton
              iconProps={{ iconName: "Save" }}
              text="Save"
              disabled={isSaveDisabled}
              onClick={handleSave}
            />
            <DefaultButton
              iconProps={{ iconName: "Cancel" }}
              text="Cancel"
              onClick={handleDismiss}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const QualityGatesModalHeader = (props: {
  isLoading: boolean;
  typeName?: string;
  titel?: string;
}) => {
  if (props.isLoading) return <Shimmer />;

  if (props.titel)
    return (
      <h2>
        {props.typeName} - {props.titel}
      </h2>
    );

  return <h2>{props.typeName}</h2>;
};
