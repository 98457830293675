import React from "react";
import { useProjectSite } from "../hooks/useProjectSite";

interface Props {
  projectId?: string;
}

export const ProjectInfo = ({ projectId }: Props) => {
  const { site } = useProjectSite(projectId);

  return (
    <div>
      <h3>Project-No.: {site?.metaProjectNo}</h3>
      <h3>Project-Name: {site?.metaProjectName}</h3>
    </div>
  );
};
