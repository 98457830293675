// https://localhost:7086/api/QualityGates?$expand=Type($expand=Company($select=CompanyCode),Phase),Status

import useSWR from "swr";
import { fetchWithTokenOdataUnwrapped } from "../../common/util/fetchWithToken";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { QualityGatesStatus } from "../types/QualityGatesStatus";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const STATUSES_FETCH_URL = `${API_BASE_URL}QualityGatesStatuses`;

export const useQualityGatesStatuses = () => {
	const { token } = useMsalToken();

	const fetchKey = token ? [STATUSES_FETCH_URL, token] : null;
	return useSWR<QualityGatesStatus[]>(fetchKey, fetchWithTokenOdataUnwrapped);
};
