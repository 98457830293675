import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { Providers, ProviderState } from "@microsoft/mgt-react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { loginRequest } from "../authConfig";

/**
 * This hook will automatically log us in
 */
export const useAuth = () => {
    const location = useLocation();
    const request = {
        ...loginRequest,
        state: location.pathname
    }
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    // This code block will trigger the Provider used in MGT Components
    useEffect(() => {
      Providers.globalProvider.setState(
        inProgress !== "none"
          ? ProviderState.Loading
          : isAuthenticated
          ? ProviderState.SignedIn
          : ProviderState.SignedOut
      );
    }, [isAuthenticated, inProgress]);
  

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Popup, request);
        }
    }, [error]);
}