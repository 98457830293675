
import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_IDENTITY_CLIENTID!,
        authority: "https://login.microsoftonline.com/b43430ce-7d75-4158-ab7b-1f39e6fe6b3f",        
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            // loggerCallback: (level, message, containsPii) => {	
            //     if (containsPii) {		
            //         return;		
            //     }		
            //     switch (level) {		
            //         case LogLevel.Error:		
            //             console.error(message);		
            //             return;		
            //         case LogLevel.Info:		
            //             console.info(message);		
            //             return;		
            //         case LogLevel.Verbose:		
            //             console.debug(message);		
            //             return;		
            //         case LogLevel.Warning:		
            //             console.warn(message);		
            //             return;		
            //     }	
            // }	
        }	
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
 export const protectedResources = {
    apiIssueLog: {
     scopes: ["api://" + process.env.REACT_APP_IDENTITY_CLIENTID + "/IssueLog.All"]
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [".default"] 
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphBaseEndpoint: "https://graph.microsoft.com/v1.0/",
    graphTeamsEndpoint: `https://graph.microsoft.com/v1.0/teams`,
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};