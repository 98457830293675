import {
	ActionButton,
	getTheme,
	IconButton,
	IIconProps,
	IPanelHeaderRenderer,
	IPanelProps,
	IStyle,
	Panel,
	PanelType,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router";
import { applicationSwichterStyles } from "./ApplicationSwichter.styles";
import { ApplicationSwitcherProps } from "./ApplicationSwitcher.types";

const PANEL_TYPE = PanelType.smallFixedNear;
const WAFFLE_ICON_PROPS: IIconProps = {
	iconName: "Waffle",
};
const WAFFLE_OPEN_STYLE: React.CSSProperties = {
	marginRight: "16px",
	color: getTheme().palette.white,
};

const WAFFLE_DISMISS_STYLE: React.CSSProperties = {
	marginLeft: "16px",
	marginRight: "auto",
};


export const ApplicationSwitcher = ({ apps }: ApplicationSwitcherProps) => {
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
		useBoolean(false);

	const { projectKey } = useParams();

	const handleRenderHeader: IPanelHeaderRenderer = () => {
		return (
			<IconButton
				iconProps={WAFFLE_ICON_PROPS}
				style={WAFFLE_DISMISS_STYLE}
				onClick={dismissPanel}
				aria-label="Waffle menu toggle"
				data-testid="toggle-button-panel"
			/>
		);
	};

	return (
		<>
			<IconButton
				iconProps={WAFFLE_ICON_PROPS}
				style={WAFFLE_OPEN_STYLE}
				onClick={openPanel}
				aria-label="Waffle menu toggle"
				data-testid="toggle-button"
			/>
			<Panel
				onRenderHeader={handleRenderHeader}
				type={PANEL_TYPE}
				isOpen={isOpen}
				onDismiss={dismissPanel}
				data-testid="app-switcher-panel"
			>
				<h3>Apps</h3>
				<div className={applicationSwichterStyles.appsContainer}>
				{apps?.map((app) => (
					<ActionButton
						iconProps={app.iconProps}
						allowDisabledFocus
						href={app.getUrl(projectKey)}
						key={app.titel}
						className={applicationSwichterStyles.button}
					>
						{app.titel}
					</ActionButton>
				))}
				</div>
			</Panel>
		</>
	);
};
