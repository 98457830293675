import axios from "axios";
import { useMsalToken } from "../../common/hooks/useMsalToken";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const QUALITY_GATES_DELETE_URL = `${API_BASE_URL}QualityGates`;


export const useQualityGatesDelete = () => {
    const { token } = useMsalToken();
        
    return (qualityGateId: number) => deleteQualityGate(token ?? "", qualityGateId)
}

function deleteQualityGate(authToken: string, qualityGateId: number) {
    if(!authToken) {
        throw new Error("Quality gate cannot be deleted due to a wrong provided auth token")
    }

    return axios.delete(
        `${QUALITY_GATES_DELETE_URL}/${qualityGateId}`,
        {
            headers: { Authorization: `Bearer ${authToken}` },
        },
    );
}
