import { mergeStyles } from "@fluentui/react";
import { Outlet, useNavigate, useParams } from "react-router";
import { PageLayoutWithAuthentication } from "../common/components/layout/PageLayoutWithAuthentication";
import { ProjectInfo } from "../common/components/ProjectInfo";
import { CreateQualityGatesCallback, QualityGatesCreateButton } from "./quality-gates-create-button/QualityGatesCreateButton";
import { QualityGatesList } from "./quality-gates-list/QualityGatesList";

const rootStyles = mergeStyles({
	margin: "0 2cm"
})

export const QualityGates = () => {
    const { projectKey } = useParams();

	const navigate = useNavigate();
	const handleClickCreateQualityGate: CreateQualityGatesCallback = (qualityGateTypeKey) => {
		navigate(`new/${qualityGateTypeKey}`)
	};
	const company = projectKey?.split(":")[0] ?? "ALL"

	return (
		<PageLayoutWithAuthentication headerAppName="Quality Log" className={rootStyles}>
            <ProjectInfo projectId={projectKey}/>
			<QualityGatesCreateButton company={company} onClickCreateQualityGate={handleClickCreateQualityGate} />
			<QualityGatesList projectKey={projectKey} />
			<Outlet />
		</PageLayoutWithAuthentication>
	);
};
