import { mergeStyleSets, Spinner } from "@fluentui/react";
import { useParams } from "react-router";
import { PageLayoutWithAuthentication } from "../common/components/layout/PageLayoutWithAuthentication";
import { ErrorBar } from "../common/components/message-bars/ErrorBar";
import { ProjectInfo } from "../common/components/ProjectInfo";
import { SquaredButton } from "../common/components/squared-button/SquaredButton";
import { useClientConfiguration } from "../common/hooks/useClientConfiguration";
import { useProjectSite } from "../common/hooks/useProjectSite";
import { ClientConfiguration } from "../common/types/ClientConfiguration";

const links = [
	{
		text: "Issue Log",
		iconName: "issue-log",
		getLink: (projectKey?: string) => `/issues/${projectKey}`,
	},
	{
		text: `Quality Log`,
		iconName: "quality-gates",
		getLink: (projectKey?: string) => `/qualitygates/${projectKey}`,
	},
	{
		text: "Business Central",
		iconName: "business-central",
		// disabled: true,
		target: "_blank",
		getLink: (projectKey?: string, clientConfiguration?: ClientConfiguration) => {
			if (!projectKey) {
				return "#";
			}

			const environment = clientConfiguration?.bcTenant;
			const splitKey = projectKey.split(":");
			const company = splitKey[0];
			const projectNo = splitKey[1];

			const filterUriComponent = encodeURIComponent(
				`Job.'No.' IS '${projectNo}'`,
			);
			const pageUriComponent = 88;
			const companyUriComponent = encodeURIComponent(`${company}`);

			return `https://businesscentral.dynamics.com/fichtnergmbh.onmicrosoft.com/${environment}?company=${companyUriComponent}&page=${pageUriComponent}&filter=${filterUriComponent}`;
		},
	},
	{
		text: "Project Reporting",
		iconName: "project-reporting",
		// disabled: true,
		target: "_blank",
		getLink: (projectKey?: string) => {
			if (!projectKey) {
				return "#";
			}
			
			const splitKey = projectKey.split(":");
			const company = splitKey[0];
			const projectNo = splitKey[1];

			const filterQuery = encodeURIComponent(`Job/Job_x0020_No_x002E_ eq '${projectNo}' and Company/Company_x0020_Name eq '${company}'`)
			
			return `https://app.powerbi.com/groups/me/apps/de914929-5f4b-417b-a6f2-1df5e441017e/reports/11dc5e4d-fd35-43de-be38-50449cbefd39/ReportSection725065111132f21164bd?filter=${filterQuery}`
		},
	},
];

const dashboardPageStyles = mergeStyleSets({
	container: {
		margin: "0 2cm",
	},
	linksWrapper: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
		paddingTop: "3rem",
	},
});

export const DashboardPage = () => {
	const { projectKey } = useParams();
	const { site, error } = useProjectSite(projectKey);
	const { data: clientConfiguration } = useClientConfiguration()

	return (
		<PageLayoutWithAuthentication headerAppName={"Dashboard"}>
			{!(site || error || clientConfiguration) && (
				<Spinner
					styles={{ root: { marginTop: "100px" } }}
					label="Loading..."
					ariaLive="assertive"
					labelPosition="top"
				/>
			)}

			{error && <ErrorBar text={error?.response?.data?.value} />}

			{site && clientConfiguration && (
				<div className={dashboardPageStyles.container}>
					<ProjectInfo projectId={projectKey} />
					<div className={dashboardPageStyles.linksWrapper}>
						{links.map(({ getLink, ...linkProps }) => {
							const link = getLink(projectKey, clientConfiguration);
							return <SquaredButton {...linkProps} href={link} />;
						})}
					</div>
				</div>
			)}
		</PageLayoutWithAuthentication>
	);
};
