import axios from "axios";
import { useMsalToken } from "../../common/hooks/useMsalToken";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * This hook provides an easy function to create new quality gates using the
 * currently stored token
 * @returns 
 */
export const useDynamicQualityGatesCreate = () => {
	const { token } = useMsalToken();

	const createQualityGate = (
		qualityGateTypeId: number | string,
		projectKey: string,
		data: { [key: string | number]: string },
	) => {
        if(!token)
            throw new Error("Cannot create Quality Gate there is no Token given")

        return createQualityGateWithToken(token, qualityGateTypeId, projectKey, data)
    };

	return createQualityGate;
};

const createQualityGateWithToken = (
	token: string,
	qualityGateTypeId: number | string,
	projectKey: string,
	data: { [key: string | number]: string },
) => {
	const qualityGatesCreateUrl = `${API_BASE_URL}QualityGatesCreate`;

	return axios.post(
		`${qualityGatesCreateUrl}?qualityGateTypeId=${qualityGateTypeId}&projectKey=${projectKey}`,
		data,
		{
			headers: { Authorization: `Bearer ${token}` },
		},
	);
};
