import useSWR from "swr";
import { fetchWithTokenOdataUnwrapped } from "../../common/util/fetchWithToken";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { QualityGatesData } from "../types/QualityGatesData";
import { RenderableQualityGatesParameterComponent } from "./QualityGatesModal.types";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useQualityGatesData = (
  projectKey: string,
  qualityGateId: string
) => {
  const qualityGatesFetchUrl = `${API_BASE_URL}QualityGatesData?projectKey=${projectKey}&qualityGateId=${qualityGateId}&$expand=Parameter`;

  const { token } = useMsalToken();

  const fetchKey = token && projectKey && qualityGateId ? [qualityGatesFetchUrl, token] : null;
  const { data, error, mutate, isValidating } = useSWR<
    RenderableQualityGatesParameterComponent[]
  >(fetchKey, fetchWithTokenOdataUnwrapped);

  const saveData = (data: Partial<QualityGatesData>) => {
    if (!token)
      throw new Error(
        "Saving QualityGates data without a token is not possible"
      );

    const qualityGatesPutUrl = `${API_BASE_URL}QualityGatesData`;

    // Update the data
    if (data.id !== undefined) {
      /// Update the issue
      return axios.patch(`${qualityGatesPutUrl}/${data.id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      // Create the data
      // UNIMPLEMENTED
    }
  };

  return { data, error, mutate, isValidating, saveData };
};
