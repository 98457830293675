import axios from "axios";

export const fetchJson = (url: string) =>
  axios.get(url).then((res) => res.data);

export const fetchWithToken = ([url, token]: [string, string]) =>
  axios
    .get(url, { headers: { Authorization: "Bearer " + token } })
    .then((res) => res.data);

export const fetchWithTokenOdataUnwrapped = ([url, token]: [string, string]) =>
  axios
    .get(url, { headers: { Authorization: "Bearer " + token } })
    .then((res) => res.data.value);
