import { useMsal } from "@azure/msal-react";
import {
	ContextualMenu,
	DatePicker,
	DayOfWeek,
	DefaultButton,
	DefaultPalette,
	DialogFooter,
	IDragOptions,
	IStackStyles,
	mergeStyleSets,
	Modal,
	defaultDatePickerStrings,
	PrimaryButton,
	Stack,
	TextField,
	IComboBoxOption,
	ComboBox,
	IStackProps,
} from "@fluentui/react";

import { IDynamicPerson } from "@microsoft/mgt-react";
import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { ExtendedPeoplePicker } from "../../common/components/form/ExtendedPeoplePicker";
import { Issue } from "../../common/types/Issue";
import { getISODateString } from "../../common/util/getISODateString";
import { useGraphToken } from "../../common/hooks/useGraphToken";
import { useProjectSite } from "../../common/hooks/useProjectSite";

const stackStyles: IStackStyles = {
	root: {
		color: DefaultPalette.black,
		overflow: "auto",
	},
};

const columnProps: Partial<IStackProps> = {
	tokens: { childrenGap: 15, padding: 5 },
	styles: { root: { width: 250 } },
};

const datePickerStyle = {
	minWidth: "50px",
	padding: -5,
};

const dateTimeFormatter = new Intl.DateTimeFormat([], {
	day: "2-digit",
	month: "2-digit",
	year: "numeric",
});

const styles = mergeStyleSets({
	container: {
		display: "flex",
		width: "85%",
		// flexFlow: 'column nowrap',
		alignItems: "stretch",
	},
	body: {
		paddingRight: "5px",
		margin: "0.75cm",
		//flex: '0 1 auto',
		// padding: '0 5px 5px 5px',
		//overflowY: 'hidden',
	},
	description: {
		color: "rgb(96, 94, 92)",
		fontSize: "10px",
		marginTop: "5px !important",
	},
	wrapper: {
		display: "grid",
		gridTemplateColumns: "repeat(3,1fr)",
		gridGap: "10px",
	},
	leftBorder: {
		"::before": {
			content: "''",
			backgroundColor: "grey",
			padding: "0.5px",
			position: "absolute",
			marginLeft: "-5px",
			height: "386%",
		},
	},
});

const dragOptions: IDragOptions = {
	moveMenuItemText: "Move",
	closeMenuItemText: "Close",
	menu: ContextualMenu,
	//dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

interface IEditIssueDialogState {
	toggleHideDialog: boolean;
}

interface Props {
	options: {
		issueTypeOptions: IComboBoxOption[];
		issuePrioOptions: IComboBoxOption[];
		issueStatusOptions: IComboBoxOption[];
	};
	initialIssue: Partial<Issue>;
	onSubmit?(
		issue: Partial<Issue>,
		asigneeUser?: User,
		reporterUser?: User,
	): void;
	onCancel?(): void;
}

export const EditIssueDialog = ({
	initialIssue,
	options,
	onSubmit,
	onCancel,
}: Props) => {
	const [issue, setIssue] = useState<Partial<Issue>>(initialIssue ?? {});

	const [assignee, setAssignee] = useState<User | undefined>();
	const [reporter, setReporter] = useState<User | undefined>();

	const { accounts } = useMsal();
	const { token } = useGraphToken();
	const { issueLogKey } = useParams();
	const { site } = useProjectSite(issueLogKey);

	// This is true if the initial issue status is closed
	const isClosed = useMemo(
		() => initialIssue.issueStatus === 3,
		[initialIssue],
	);

	useEffect(() => {
		// Set default date for reported date to the current date if it is not set
		if (!issue.issueByDate) {
			setIssue((issue) => ({
				...issue,
				issueByDate: getISODateString(new Date()),
			}));
		}
		// Set default value for reported by to the current signed in user
		if (!issue.issueBy) {
			// The Username seems to be the UPN
			setIssue((issue) => ({ ...issue, issueBy: accounts[0].username }));
		}

		// Set default value to 4=None for issue priority
		if (!issue.issuePrio) {
			setIssue((issue) => ({ ...issue, issuePrio: 4 }));
		}

		// Set default value to 1=Open for issue status
		if (!issue.issueStatus) {
			setIssue((issue) => ({ ...issue, issueStatus: 1 }));
		}
	}, []);

	const isSubmitDisabled = (): boolean => {

			// If the state is closed whe check more fields
			if(issue.issueStatus === 3) {
				if(!issue.issueResult)  {
					return true;
				}
				if(issue.issueLessonLearned == null) {
					return true;
				}
			}

			return !issue.issueName ||
			!issue.issueType ||
			!issue.issueBy ||
			!issue.issueDesc || isClosed;
	}

	return (
		<Modal
			containerClassName={styles.container}
			isBlocking={true}
			isOpen={true}
			onDismiss={() => onCancel}
			//dragOptions={dragOptions}
		>
			<div className={styles.body}>
				<Stack styles={stackStyles} tokens={{ childrenGap: 0 }}>
					<div className={styles.wrapper}>
						<div>
							<ComboBox
								defaultSelectedKey={issue.issueStatus}
								options={options.issueStatusOptions}
								onChange={(_, option) =>
									setIssue((issue) => ({
										...issue,
										issueStatus: option?.key as number,
									}))
								}
								label="Status"
								placeholder="---"
								disabled={isClosed}
							/>
							<span className={styles.description}>
								Current status of issue
							</span>
						</div>
						<div>
							<DatePicker
								style={datePickerStyle}
								firstDayOfWeek={DayOfWeek.Sunday}
								showWeekNumbers={true}
								firstWeekOfYear={1}
								showMonthPickerAsOverlay={true}
								placeholder="Insert date"
								ariaLabel="Select a date"
								label="Reported Date"
								onSelectDate={(date) =>
									setIssue((issue) => ({
										...issue,
										issueByDate: getISODateString(date!),
									}))
								}
								value={
									typeof issue.issueByDate === "string"
										? new Date(issue.issueByDate)
										: issue.issueByDate
								}
								formatDate={(date) => dateTimeFormatter.format(date)}
								strings={defaultDatePickerStrings}
								disabled={isClosed}
							/>
							{/*Set marginTop to 0 for span after Date Picker, because Date Picker itself is bigger*/}
							<span className={styles.description} style={{ marginTop: 0 }}>
								Date when issue was reported
							</span>
						</div>
						<div>
							<ComboBox
								defaultSelectedKey={issue.issuePrio}
								options={options.issuePrioOptions}
								onChange={(_, option) =>
									setIssue((issue) => ({
										...issue,
										issuePrio: option?.key as number,
									}))
								}
								label="Priority"
								disabled={isClosed}
							/>
							<span className={styles.description}>
								Priority of issue with effect on necessity and urgency of
								mitigation measures
							</span>
						</div>
					</div>
					{/*width of horizontal line set to 99%, because with 100% there is a scrollbar at the bottom for the dialog*/}
					<hr style={{ color: "grey", width: "99%", marginTop: "0.75rem" }} />
					<div className={styles.wrapper}>
						<TextField
							placeholder="Please type in a value"
							label="Issue Name"
							defaultValue={issue.issueName}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issueName: newVal ?? "" }))
							}
							required={true}
							disabled={isClosed}
						/>
						<div>
							<ComboBox
								placeholder="---"
								defaultSelectedKey={issue.issueType}
								options={options.issueTypeOptions}
								onChange={(_, option) =>
									setIssue((issue) => ({
										...issue,
										issueType: option?.key as number,
									}))
								}
								label="Issue Type"
								required={true}
								disabled={isClosed}
							/>
							<span className={styles.description}>Select type of issue</span>
						</div>
						<TextField
							placeholder="Please type in a value"
							multiline={true}
							rows={3}
							label="Measures"
							defaultValue={issue.issueMeasures}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issueMeasures: newVal }))
							}
							description="Mitigation Measures"
							disabled={isClosed}
							className={styles.leftBorder}
						/>

						<TextField
							placeholder="Please type in a value"
							multiline={true}
							required={true}
							rows={3}
							label="Description"
							defaultValue={issue.issueDesc}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issueDesc: newVal }))
							}
							description="Description of issue and its implications and if necessary mitigation measures"
							disabled={isClosed}
						/>
						<TextField
							label="Documentation"
							multiline={true}
							defaultValue={issue.issueDocu}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issueDocu: newVal }))
							}
							description="Link to photos/documents"
							disabled={isClosed}
						/>

						<ExtendedPeoplePicker
							groupId={site?.groupId}
							label="Assigned To"
							description="Assignee in charge of mitigation meassures"
							placeholder="Insert name or email address"
							selectionMode="single"
							defaultSelectedUserIds={[issue.issueTo ?? ""]}
							onSelectPeople={(people: IDynamicPerson[]) => {
								setIssue((issue) => ({
									...issue,
									issueTo:
										(people.at(0) as User)?.userPrincipalName ?? undefined,
								}));
								setAssignee((people.at(0) as User) ?? undefined);
							}}
							disabled={isClosed}
						/>
						<TextField
							label="Work Package"
							placeholder="Please type in a value"
							defaultValue={issue.issuePackage}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issuePackage: newVal }))
							}
							description="Work Package affected by issue"
							disabled={isClosed}
						/>
						<ExtendedPeoplePicker
							label="Reported By"
							description="Person that reported the issue"
							placeholder="Insert name or email address"
							selectionMode="single"
							defaultSelectedUserIds={[issue.issueBy ?? ""]}
							onSelectPeople={(people: IDynamicPerson[]) => {
								setIssue((issue) => ({
									...issue,
									issueBy:
										(people.at(0) as User)?.userPrincipalName ?? undefined,
								}));
								setReporter((people.at(0) as User) ?? undefined);
							}}
							disabled={true}
							required={true}
						/>
						<div>
							<DatePicker
								style={datePickerStyle}
								firstDayOfWeek={DayOfWeek.Sunday}
								showWeekNumbers={true}
								firstWeekOfYear={1}
								showMonthPickerAsOverlay={true}
								placeholder="Insert date"
								ariaLabel="Select a date"
								label="Target Date"
								disabled={isClosed}
								onSelectDate={(date) =>
									setIssue((issue) => ({
										...issue,
										issueTargetDate: getISODateString(date!),
									}))
								}
								value={
									typeof issue.issueTargetDate === "string"
										? new Date(issue.issueTargetDate)
										: issue.issueTargetDate
								}
								formatDate={(date) => dateTimeFormatter.format(date)}
								strings={defaultDatePickerStrings}
							/>
							{/*Set marginTop to 0 for span after Date Picker, because Date Picker itself is bigger*/}
							<span className={styles.description} style={{ marginTop: 0 }}>
								Date when issue and/or associated action needs to be closed
							</span>
						</div>
						<div />
						<div />
						<ComboBox
							placeholder="Please select a value"
							label="Relevant for Lesson Learned"
							defaultSelectedKey={
								issue.issueLessonLearned == null
									? undefined
									: issue.issueLessonLearned
									? "1"
									: "0"
							}
							options={[
								{ key: "1", text: "Yes" },
								{ key: "0", text: "No" },
							]}
							// Only required if the issue is changing to closed
							required={issue.issueStatus === 3}
							onChange={(_, option) => {
								let issueLessonLearned: boolean | undefined;

								switch (option?.key) {
									case "1":
										issueLessonLearned = true;
										break;
									case "0":
										issueLessonLearned = false;
										break;
									default:
										issueLessonLearned = undefined;
								}

								setIssue((issue) => ({
									...issue,
									issueLessonLearned,
								}));
							}}
							disabled={isClosed}
						/>
						<div />
						<div />
						<TextField
							placeholder="Please type in a value"
							multiline={true}
							rows={3}
							label="Result"
							defaultValue={issue.issueResult}
							onChange={(_, newVal) =>
								setIssue((issue) => ({ ...issue, issueResult: newVal }))
							}
							// Only required if the issue is changing to closed
							required={issue.issueStatus === 3}
							disabled={isClosed}
						/>
					</div>
				</Stack>
				<hr style={{ color: "grey", width: "99%", marginTop: "0.75rem" }} />
				<DialogFooter>
					<PrimaryButton
						iconProps={{ iconName: "Save" }}
						onClick={() => onSubmit?.(issue, assignee, reporter)}
						text="Save"
						disabled={isSubmitDisabled()}
					/>
					<DefaultButton
						iconProps={{ iconName: "Cancel" }}
						onClick={() => onCancel?.()}
						text="Cancel"
					/>
				</DialogFooter>
			</div>
		</Modal>
	);
};
