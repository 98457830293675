import { FontIcon, IColumn, Link } from "@fluentui/react";
import { Person, ViewType } from "@microsoft/mgt-react";
import { Issue } from "../../../common/types/Issue";
import { IssuePriority } from "../../../common/types/IssuePriority";
import { IssueStatus } from "../../../common/types/IssueStatus";
import { IssueType } from "../../../common/types/IssueType";
import { IssueListSortingSettings } from "./IssueList";
import { issueListStyles } from "./IssueList.styles";
import { issueListEnumerationStylings } from "./IssueList.styles";
import { getIssueEnumContentById } from "../../../common/util/getIssueEnumContentById";
import { dateTimeFormatter } from "../../../common/util/dateTimeFormatter";

export function getRenderItemColumnFunction(
	issuePriorities: IssuePriority[],
	issueStatuses: IssueStatus[],
	issueTypeNavigations: IssueType[],
	setSelectedIssue: (issue: Issue) => void,
) {
	return (item?: Issue, index?: number, column?: IColumn) => {
		if (!item) {
			return;
		}

		const fieldContent = item[column?.fieldName as keyof Issue];
		switch (column?.key) {
			case "issueEdit":
				return (
					<Link
						onClick={() => setSelectedIssue({ ...item })}
						id={item.id.toString()}
						underline={true}
					>
						<FontIcon aria-label="Edit" iconName="Edit" />
					</Link>
				);

			case "issueByDate":
			case "issueTargetDate": {
				const currentDateItem = new Date(item[column.key as keyof Issue]);
				// Guard to check if the date is valid
				if (Number.isNaN(currentDateItem.getTime())) {
					return fieldContent;
				}

				return dateTimeFormatter.format(currentDateItem);
			}
            case "issueType":
            case "issueStatus":
			case "issuePrio": {
				const collectionField = {
					issuePrio: "issuePriority",
					issueStatus: "issueStatus",
					issueType: "issueType",
				}[column.key];

				const collection = {
					issuePrio: issuePriorities,
					issueStatus: issueStatuses,
					issueType: issueTypeNavigations,
				}[column.key];
                // Typescript does not seem to like this but it works with any
				const content = getIssueEnumContentById<any>(
					fieldContent,
					collectionField,
					collection,
				);
				return (
					<span
						className={issueListStyles.enumerationChip}
						style={issueListEnumerationStylings[content]}
					>
						{content}
					</span>
				);
			}
			case "issueTo":
			case "issueBy": {
				return <Person userId={fieldContent} view={ViewType.oneline} />;
			}
			case "issueDocu":
				return (
					<Link href={fieldContent} target="_blank" underline={true}>
						{" "}
						{fieldContent}
					</Link>
				);
			case "issueLessonLearned": {
				if(fieldContent == null) {
					return undefined;
				}

				// Field Content is a bool
				if(!fieldContent) {
					return "No"
				}

				return "Yes"
			}
			default:
				return fieldContent;
		}
	};
}

export function getIssueListColumns(
	onClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
	onColumnContextMenu: (
		column?: IColumn,
		ev?: React.MouseEvent<HTMLElement>,
	) => void,
	sortingSettings?: IssueListSortingSettings,
): (IColumn & { isGroupable?: boolean })[] {
	return [
		{
			key: "issueStatus",
			name: "Status",
			fieldName: "issueStatus",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueName",
			name: "Issue Name",
			fieldName: "issueName",
			minWidth: 220,
		},
		{
			key: "issueType",
			name: "Issue Type",
			fieldName: "issueType",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueDesc",
			name: "Description",
			fieldName: "issueDesc",
			isMultiline: true,
			minWidth: 320,
		},
		{
			key: "issuePrio",
			name: "Priority",
			fieldName: "issuePrio",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueDocu",
			name: "Documentation",
			fieldName: "issueDocu",
			minWidth: 220,
		},
		{
			key: "issuePackage",
			name: "Work Package",
			fieldName: "issuePackage",
			isGroupable: true,
			minWidth: 220,
		},			
		{
			key: "issueBy",
			name: "Reported By",
			fieldName: "issueBy",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueByDate",
			name: "Reported Date",
			fieldName: "issueByDate",
			isGroupable: true,
			minWidth: 100,
		},		
		{
			key: "issueMeasures",
			name: "Measures",
			fieldName: "issueMeasures",
      isMultiline: true,
			minWidth: 220,
		},	
		{
			key: "issueTo",
			name: "Assigned To",
			fieldName: "issueTo",
			isGroupable: true,
			minWidth: 100,
		},		
		{
			key: "issueTargetDate",
			name: "Target Date",
			fieldName: "issueTargetDate",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueResult",
			name: "Result",
			fieldName: "issueResult",
			isGroupable: true,
			minWidth: 100,
		},
		{
			key: "issueLessonLearned",
			name: "Lesson Learned",
			fieldName: "issueLessonLearned",
			isGroupable: true,
			minWidth: 100,
		},
	].map((column) => ({
		...column,
		// minWidth: 100,
		//maxWidth: 200,
		isResizable: true,
		onColumnClick: onClick,
		onColumnContextMenu: onColumnContextMenu,
		isGrouped: sortingSettings?.groupBy === column.key,
		isSorted: sortingSettings?.sortBy === column.key,
		isSortedDescending:
			sortingSettings?.sortBy === column.key &&
			sortingSettings?.isSortedDescending,
	}));
}
