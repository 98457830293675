import { IColumn } from "@fluentui/react";

export const qualityGateColumns: IColumn[] = [
    {
        name: "Process Group",
        fieldName: "name",
        minWidth: 500,
        maxWidth: 500,
    },
    {
        name: "Status",
        fieldName: "status",
        minWidth: 75,
        maxWidth: 75,
    },
    {
        name: "Created By",
        fieldName: "createdBy",
        minWidth: 200,
        maxWidth: 200,
    },
    {
        name: "Created Date",
        fieldName: "createdDate",
        minWidth: 100,
        maxWidth: 100,
    },

    {
        name: "Closed By",
        fieldName: "closedBy",
        minWidth: 200,
        maxWidth: 200,
    },
    {
        name: "Closed Date",
        fieldName: "closedDate",
        minWidth: 100,
        maxWidth: 100,
    }
].map((column) => ({
    ...column,
    key: column.fieldName,
    isResizable: true,
}));