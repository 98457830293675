// https://localhost:7086/api/QualityGates?$expand=Type($expand=Company($select=CompanyCode),Phase),Status

import axios from "axios";
import { IGroup } from "@fluentui/react";
import useSWR from "swr";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { QualityGate } from "../types/QualityGates";
import { QualityGatesListItem } from "./QualityGatesList.types";
import {
  PHASES_FETCH_URL,
} from "./useQualityGatesPhases";
import { QualityGatesPhase } from "../types/QualityGatesPhase";
import { QG_STATUS_CLOSED_ID, QG_STATUS_OPEN_ID, QG_TYPE_DELIVERABLE_ID, QG_TYPE_PLANNING_MODIFICATION_ID } from "../../common/constants";
import { parseProjectModificationData } from "../../common/util/projectModificationDataParser";
import { STATUSES_FETCH_URL } from "./useQualityGateStatuses";
import { QualityGatesStatus } from "../types/QualityGatesStatus";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useQualityGatesListItems = (projectKey?: string, openGroups: string[] = []) => {
  const { token } = useMsalToken();

  const qualityGatesFetchUrl = `${API_BASE_URL}QualityGates?projectKey=${projectKey}&$expand=Type($expand=Company($select=CompanyCode),Phase),Status,Data`;

  const fetchKey = token && projectKey ? [qualityGatesFetchUrl, token] : null;
  return useSWR<QualityGatesListItemsResponse>(
    fetchKey,
    (params: [string, string]) => fetchQualityGatesListItems([...params, openGroups])
  );
};

interface QualityGatesListItemsResponse {
  listItems: QualityGatesListItem[];
  groups: IGroup[];
}

/**
 * Specific fetcher to use with SWR
 * This fetches the Items and then also generates the groups
 * @param url
 * @param token
 * @returns
 */
async function fetchQualityGatesListItems([url, token, openGroups]: [
  string,
  string,
  string[]
]): Promise<QualityGatesListItemsResponse> {
  const requestHeaders = { Authorization: "Bearer " + token };
  const qualityGates: QualityGate[] = await axios
    .get(url, { headers: requestHeaders })
    .then((res) => res.data.value);

  const phases: QualityGatesPhase[] = await axios
    .get(PHASES_FETCH_URL, { headers: { "max-age": 3600, ...requestHeaders } })
    .then((res) => res.data.value);

  const statuses: QualityGatesStatus[] = await axios
    .get(STATUSES_FETCH_URL, { headers: { "max-age": 3600, ...requestHeaders } })
    .then((res) => res.data.value);

  const listItems = getListItems(qualityGates, statuses);
  const groups = getGroups(listItems, phases, openGroups);

  return {
    groups,
    listItems,
  };
}

// This function is not pretty
// Curently generates the list
// Some functionality of this should live in the backend
function getListItems(qualityGates: QualityGate[], statuses: QualityGatesStatus[]): QualityGatesListItem[] {
  // Deliverable Name
  const today = new Date();
  const deliverableNameParameterId = 6;
  const listItems = qualityGates
    .map((item) => {
      const listItem = {
        id: item.id,

        // Specific change for the deliverable so that this its name is shown
        // instead of the type name
        name:
          item.typeId !== QG_TYPE_DELIVERABLE_ID
            ? item.type?.name
            : item.type?.name + " - " + (item.data?.find((i) => i.parameterId === deliverableNameParameterId)
              ?.data),
        desciption: item.type?.description,

        createdDate: item.createdDate,
        createdBy: item.createdBy,

        closedDate: item.closedDate,
        closedBy: item.closedBy,

        type: item.type,
        status: item.status,
        phase: item.type?.phase,
      }

      // // If this is a Planning modificiation we have to apply some more logic
      // // If the last entry is from this month we show closed and closed by date based on the data
      // // If the last entry is not from this month it is still open
      if (item.type?.id === QG_TYPE_PLANNING_MODIFICATION_ID && item.data?.[0] != null) {
        const planningModificationData = item.data[0];
        const parsedPlanningData = parseProjectModificationData(planningModificationData.data);

        const containsDateFromThisMonth = parsedPlanningData ? Object.values(parsedPlanningData).some(obj => {
          // The date is always index 2
          const dateData = obj[2];
          const date = new Date(dateData);

          const isClosed = date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();

          if(isClosed) {
            // Lets update the data here
            listItem.closedBy = obj[0]
            listItem.closedDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
            listItem.status = statuses.find(status => status.id === QG_STATUS_CLOSED_ID) ?? undefined;
          }

          return isClosed;
        }) : false;

        if(!containsDateFromThisMonth) {
          listItem.closedBy = undefined;
          listItem.closedDate = undefined;
          listItem.status = statuses.find(status => status.id === QG_STATUS_OPEN_ID) ?? undefined;
        }
      }

      return listItem;
    })
    // The items must be sorted to group them
    .sort((a, b) => (a.phase?.id ?? 0) - (b.phase?.id ?? 0));

  return listItems;
}

/**
 * This function generates groups to give to the DetailsList
 * @param listItems
 * @param phases
 * @returns
 */
function getGroups(
  listItems: QualityGatesListItem[],
  phases: QualityGatesPhase[],
  openGroups: string[]
): IGroup[] {
  const groupsRecord: Record<string, IGroup> = {};
  const phaseNames =
    phases.reduce((prev, currentVal) => {
      prev[currentVal.id] = currentVal.name;
      return prev;
    }, {} as Record<number, string>) ?? [];

  for (let index = 0; index < listItems.length; index++) {
    const currentQualityGate = listItems[index];

    // The current field that is used to group the list
    const groupingValue = currentQualityGate.type?.phaseId;
    if (!groupingValue) {
      throw new Error(
        `Phase ID is null in ${currentQualityGate.id} grouping failed`
      );
    }

    if (groupingValue in groupsRecord) {
      groupsRecord[groupingValue].count++;
      continue;
    }

    const phaseName = phaseNames[groupingValue] ?? "";

    groupsRecord[groupingValue] = {
      key: `group-${groupingValue}`,
      name: phaseName,
      startIndex: index,
      count: 1,
      level: 0,
      isCollapsed: !openGroups.includes(`group-${groupingValue}`)
    };
  }

  // Map out all the groups to an IGroup array. Index 1 is the IGroup object
  // index 0 would be the groupingValue
  const groups = Object.entries(groupsRecord).flatMap((val) => val[1]);
  return groups;
}
