import { mergeStyles } from "@fluentui/react";

interface BadgeProps
	extends React.PropsWithChildren<React.HTMLAttributes<HTMLSpanElement>> {
	color: string;
	backgroundColor: string;
}

const badgeStyleClass = mergeStyles({
	borderRadius: "50px",
	padding: "3px 10px",
});

export const Badge = ({
	color,
	backgroundColor,
	children,
	...props
}: BadgeProps) => (
	<span
		className={badgeStyleClass}
		style={{
			color,
			backgroundColor,
		}}
        
        {...props}
	>
		{children}
	</span>
);
