import useSWR from "swr";
import { Issue } from "../types/Issue";
import {
  fetchWithToken,
  fetchWithTokenOdataUnwrapped,
} from "../util/fetchWithToken";
import { useMsalToken } from "./useMsalToken";
import axios from "axios";
import { IssueStatus } from "../types/IssueStatus";
import { IssuePriority } from "../types/IssuePriority";
import { IssueType } from "../types/IssueType";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ISSUES_URL = `${BASE_URL}fgprojectIssueLog/`;

export const useIssues = (issueLogKey?: string) => {
  const { token } = useMsalToken();

  const {
    data: issues,
    error: issuesError,
    mutate: mutateIssues,
  } = useSWR<Issue[], any>(
    !token || !issueLogKey
      ? null
      : [`${BASE_URL}fgprojectIssueLog?issueLogKey=${issueLogKey}`, token],
    fetchWithTokenOdataUnwrapped,
    { refreshInterval: 15000 }
  );

  const { data: issueTypeNavigations, error: issueTypeNavigationsError } =
    useSWR<IssueType[], any>(
      !token ? null : [`${BASE_URL}IssueTypeNavigation`, token],
      fetchWithTokenOdataUnwrapped
    );

  const { data: issueStatuses, error: issueStatusesError } = useSWR<
    IssueStatus[],
    any
  >(
    !token ? null : [`${BASE_URL}IssueStatus`, token],
    fetchWithTokenOdataUnwrapped
  );

  const { data: issuePriorities, error: issuePrioritiesError } = useSWR<
    IssuePriority[],
    any
  >(
    !token ? null : [`${BASE_URL}IssuePrio`, token],
    fetchWithTokenOdataUnwrapped
  );

  const saveIssue = (issue: Partial<Issue>) => {
    if (!token)
      throw new Error("Saving an issue without a token is not possible");

    // Update the issue
    if (issue.id !== undefined) {
      // We want to create a new issue if our id is not provided
      //PATCH
      axios
        .patch(`${ISSUES_URL}${issue.id}`, issue, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => res.data)
        .catch((error) => console.log(error));

      // Create a new array with our modified issue and update it in only 
      // in the frontend
      const optimisticIssues = issues
        ? issues.map((iss) => {
            if (iss.id !== issue.id) {
              return iss;
            }
            return { ...iss, ...issue };
          })
        : [issue];

      mutateIssues(optimisticIssues as Issue[]);
    } else {
      axios
        .post(`${ISSUES_URL}`, issue, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => mutateIssues(issues ? [...issues, res.data] : [res.data]))
        .catch((error) => console.log(error));
    }
  };

  return {
    issues,
    issueTypeNavigations,
    issueStatuses,
    issuePriorities,
    error: [
      issuesError,
      issueTypeNavigationsError,
      issueStatusesError,
      issuePrioritiesError,
    ].find((v) => v !== null),
    saveIssue,
  };
};
