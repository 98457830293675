import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { protectedResources } from "../authConfig";


export const useMsalToken = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState<string>();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                account: accounts[0],
                scopes:  protectedResources.apiIssueLog.scopes
            }).then(response => {
                setToken(response.accessToken)
            })
        }
    }, [inProgress, isAuthenticated, accounts, instance]);
    return {token}
}