import {
	IContextualMenuProps,
	IContextualMenuItem,
	DirectionalHint,
} from "@fluentui/react";
import React from "react";
import { GroupableColumn } from "../../../common/types/GroupableColumn";
import { Issue } from "../../../common/types/Issue";
import { IssueListSortingSettings } from "./IssueList";

export function getIssueListHeaderContextualMenuProps(
	ev: React.MouseEvent<HTMLElement>,
	column: GroupableColumn,
	setSortSettings: React.Dispatch<
		React.SetStateAction<IssueListSortingSettings | undefined>
	>,
	setContextMenuProps: React.Dispatch<
		React.SetStateAction<IContextualMenuProps | undefined>
	>,
): IContextualMenuProps {
	const items: IContextualMenuItem[] = [
		{
			key: "ascending",
			name: "Sort ascending",
			iconProps: { iconName: "SortUp" },
			canCheck: true,
			checked: column.isSorted && !column.isSortedDescending,
			onClick: () =>
				setSortSettings({
					sortBy: column.key as keyof Issue,
					isSortedDescending: false,
				}),
		},
		{
			key: "descinding",
			name: "Sort descending",
			iconProps: { iconName: "SortDown" },
			canCheck: true,
			checked: column.isSorted && column.isSortedDescending,
			onClick: () =>
				setSortSettings({
					sortBy: column.key as keyof Issue,
					isSortedDescending: true,
				}),
		},
	];
	if (column.isGroupable) {
		items.push({
			key: "groupBy",
			name: `Group by ${column.name}`,
			iconProps: { iconName: "GroupedDescending" },
			canCheck: true,
			checked: column.isGrouped,
			onClick: () =>
				setSortSettings({
					sortBy: column.key as keyof Issue,
					isSortedDescending: true,
					groupBy: column.key as keyof Issue,
				}),
		});
	}
	return {
		items: items,
		target: ev.currentTarget as HTMLElement,
		directionalHint: DirectionalHint.bottomLeftEdge,
		gapSpace: 10,
		isBeakVisible: true,
        // Disable the context menu
		onDismiss: () => setContextMenuProps(undefined),
	};
}
