import { mergeStyles, mergeStyleSets } from "@fluentui/react";

export const sqauredButtonStyle = mergeStyles({
    width: "144px",
    backgroundColor: "#005AAF",
    
    '> .ms-Button-flexContainer': {
        display: "flex",
        flexDirection: "column"
    },
    '> .ms-Button-flexContainer > .ms-Icon': {
        width: "60px",
        height: "61px",
        fill: "#fff",
        paddingBottom: "5px"
    },
    '> .ms-Button-flexContainer > *': {
        margin: "0 auto",
        textAlign: "center"
    }
})