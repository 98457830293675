import {
  Checkbox,
  DatePicker,
  Dropdown,
  Label,
  Separator,
  ShimmeredDetailsList,
  TextField,
} from "@fluentui/react";
import { IDynamicPerson, Person, ViewType } from "@microsoft/mgt-react";
import { StringLiteral } from "typescript";
import { ParameterDataJsonText } from "../types/ParameterDataJsonText";
import {
  ModalFormElement,
  RenderableQualityGatesParameterComponent,
} from "./QualityGatesModal.types";
import { QualityGatesParameter } from "../types/QualityGatesParameter";
import { ExtendedPeoplePicker } from "../../common/components/form/ExtendedPeoplePicker";
import { User } from "@microsoft/microsoft-graph-types";
import { qualityGatesModalStyles } from "./QualityGatesModal.styles";
import ParameterLabel from "./QualityGatesModalParameterLabel";
import { QG_PARAMETER_NAME_REVISION, QG_PARAMETER_TYPE_CHECKBOX_NAME, QG_PARAMETER_TYPE_DATE_NAME, QG_PARAMETER_TYPE_DROPDOWN_NAME, QG_PARAMETER_TYPE_FILEPICKER_NAME, QG_PARAMETER_TYPE_JSONTEXT_NAME, QG_PARAMETER_TYPE_PEOPLEPICKER_NAME, QG_PARAMETER_TYPE_SEPERATOR_NAME, QG_PARAMETER_TYPE_TEXTINPUT_NAME } from "../../common/constants";
import { parseProjectModificationData } from "../../common/util/projectModificationDataParser";

/**
 * This function uses the QualityGatesData object that is retrived from the backend and renders the different components out.
 * We also use this Function to handle the Parameter Updates and pass the new value out
 * @param param0
 * @param handleParameterUpdate
 * @returns
 */
export const renderParameterComponent = (
  modalFormElement: ModalFormElement,
  handleParameterUpdate: (
    modalFormElement: ModalFormElement,
    value?: string
  ) => void
) => {
  const parameterType = modalFormElement.parameterType.toLowerCase();
  // Maby it is already a string then wi will use the data as parsed daa
  let parsedData;
  try {
    parsedData = JSON.parse(modalFormElement.dataValue);
  } catch (e) {
    parsedData = modalFormElement.dataValue;
  }

  // In between method this is used to implement custom checks like if the checkbox is already checked then we will not handle a change but instead send a info value back through the update function
  const handleChange = (value?: string) => {
    handleParameterUpdate(modalFormElement, value);
  };

  const { parameterId, parameterName, parameterRequired } = modalFormElement;

  switch (parameterType) {
    case QG_PARAMETER_TYPE_CHECKBOX_NAME:
      return (
        <Checkbox
          className={qualityGatesModalStyles.fullModelParameterWidth}
          key={parameterId}
          label={parameterName}
          defaultChecked={typeof parsedData === "boolean" && parsedData}
          // disabled={typeof parsedData === "boolean" && parsedData}
          required={parameterRequired}
          onChange={(_ev, changeValue) => handleChange(String(changeValue))}
          boxSide="end"
        />
      );

    case QG_PARAMETER_TYPE_JSONTEXT_NAME:
      if(typeof(modalFormElement.dataValue) !== "string") {
        // ??
        console.error("dataValue is not a string or object cannot continue.", modalFormElement.dataValue)
        return;
      }

      // This regex matches a rough js object outline lets parse it like this
      const parsedValues = parseProjectModificationData(modalFormElement.dataValue);
      
      const items = parsedValues ? Object.values(parsedValues) : [];
      return (
        <div className={qualityGatesModalStyles.fullModelParameterWidth}>
          <ShimmeredDetailsList
            items={items}
            key={"data-list"}
            columns={[
              {
                key: "name",
                name: "Name",
                fieldName: "0",
                minWidth: 0,
                onRender: (item, index, column) => {
                  return <Person userId={item[0]} view={ViewType.oneline} />;
                },
              },
              // {
              //   key: "1",
              //   name: "State",
              //   fieldName: "1",
              //   minWidth: 0,
              // },
              {
                key: "2",
                name: "Date",
                fieldName: "2",
                minWidth: 120,
              },
            ]}
          />
        </div>
      );
    case QG_PARAMETER_TYPE_DROPDOWN_NAME: {
      switch (parameterName) {
        case "Deliverable Categorie":
          // This will not be renderd and the default
          // value for this is set to 0 in the
          // QualityGatesModal.tsx file
          return;
        case QG_PARAMETER_NAME_REVISION:
          return (
            <>
              <ParameterLabel
                label={parameterName}
                required={parameterRequired}
              />
              <Dropdown
                options={Array.from(Array(11).keys()).map((val) => ({
                  key: val,
                  text: String(val),
                }))}
                defaultSelectedKey={parsedData}
                onChange={(_ev, changeValue) =>
                  handleChange(JSON.stringify(changeValue?.key))
                }
              />
            </>
          );
        default:
          return (
            <>
              <ParameterLabel
                label={parameterName}
                required={parameterRequired}
              />
              <Dropdown
                options={[]}
                defaultSelectedKey={parsedData}
                onChange={(_ev, changeValue) =>
                  handleChange(JSON.stringify(changeValue?.key))
                }
              />
            </>
          );
      }
    }
    case QG_PARAMETER_TYPE_TEXTINPUT_NAME:
      return (
        <>
          <ParameterLabel label={parameterName} required={parameterRequired} />
          <TextField
            defaultValue={parsedData}
            onChange={(_ev, changeValue) => handleChange(changeValue)}
          />
        </>
      );

    case QG_PARAMETER_TYPE_SEPERATOR_NAME:
      return <hr className={qualityGatesModalStyles.modalParameterSeperator} />;

    case QG_PARAMETER_TYPE_DATE_NAME:
      return (
        <>
          <ParameterLabel label={parameterName} required={parameterRequired} />
          <DatePicker
            allowTextInput
            value={parsedData ? new Date(parsedData) : undefined}
            onSelectDate={(changeValue) =>
              handleChange(changeValue?.toISOString())
            }
            firstDayOfWeek={1}
            showGoToToday={true}
          />
        </>
      );
    case QG_PARAMETER_TYPE_PEOPLEPICKER_NAME:
      return (
        <>
          <ParameterLabel label={parameterName} required={parameterRequired} />
          <ExtendedPeoplePicker
            onSelectPeople={function (people: IDynamicPerson[]): void {
              if (people.length === 0) {
                handleChange();
              } else {
                handleChange(
                  (people[0] as User)?.userPrincipalName ?? undefined
                );
              }
            }}
            defaultSelectedUserIds={parsedData ? [parsedData] : []}
            selectionMode={"single"}
          />
        </>
      );
    case QG_PARAMETER_TYPE_FILEPICKER_NAME:
      return (
        <>
          <ParameterLabel label={parameterName} required={parameterRequired} />
          <TextField
            content={parsedData}
            defaultValue={parsedData}
            onChange={(_ev, changeValue) => handleChange(changeValue)}
          />
        </>
      );

    default:
      return <p>Unimplemented Parameter type: {parameterType}</p>;
  }
};
