import { IColumn } from "@fluentui/react";
import { Person, ViewType } from "@microsoft/mgt-react";
import { Badge } from "../../common/components/Badge";
import { dateTimeFormatter } from "../../common/util/dateTimeFormatter";
import { QualityGatesPhase } from "../types/QualityGatesPhase";
import { QualityGatesStatus } from "../types/QualityGatesStatus";
import { QualityGatesListItem } from "./QualityGatesList.types";

export const renderItemColumn: (
  item?: unknown,
  index?: number,
  column?: IColumn
) => React.ReactNode = (item?, index?, column?) => {
  // Lets guarantee the safety of these variables from here on
  const itemExists = item != null;
  const isItemObject = typeof item === "object";
  const columnKeyExists = column?.key != null;
  // rome-ignore lint/complexity/useSimplifiedLogicExpression: This looks simpler to understand
  if (!itemExists || !isItemObject || !columnKeyExists) {
    return;
  }
  const itemContainsKey = column.key in item;
  if (!itemContainsKey) return;

  // rome-ignore lint/suspicious/noExplicitAny: dynamic value and key exists
  const currentValue: unknown = (item as any)[column.key];

  const currentValueExists = currentValue != null;
  if (!currentValueExists) return;

  switch (column.key as keyof QualityGatesListItem) {
    case "status":
    case "phase":
      const statusOrPhase = currentValue as
        | QualityGatesStatus
        | QualityGatesPhase;
      return (
        <Badge
          color={statusOrPhase.fontColor}
          backgroundColor={statusOrPhase.backgroundColor}
        >
          {statusOrPhase.name}
        </Badge>
      );
    case "closedDate":
    case "createdDate":
      const currentDateItem = new Date(currentValue as string);
      // Guard to check if the date is valid
      if (Number.isNaN(currentDateItem.getTime())) {
        return;
      }

      return dateTimeFormatter.format(currentDateItem);
    case "createdBy":
    case "closedBy":
      const userId = currentValue as string;
      return <Person userId={userId} view={ViewType.oneline} />;
    default:
      return currentValue as string;
  }
};
