import { useState } from "react";
import useSWR from "swr";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { fetchWithTokenOdataUnwrapped } from "../../common/util/fetchWithToken";
import { QualityGatesParameter } from "../types/QualityGatesParameter";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Fetches parameters from the /api/QualityGatesParameters endpoint
 */
export const useQualityGatesParameters = (
	qualityGateTypeId?: number | string,
) => {
	const { token } = useMsalToken();

	const odataFilterQueryValue = encodeURIComponent(
		`typeId eq ${qualityGateTypeId}`,
	);
	const fetchParametersUrl = `${API_BASE_URL}QualityGatesParameters?filter=${odataFilterQueryValue}`;

	const isTypeIdGiven = Boolean(qualityGateTypeId);
	const fetchParametersKey = isTypeIdGiven && token ? [fetchParametersUrl, token] : null;
	
	const swrResponse = useSWR<QualityGatesParameter[]>(
		fetchParametersKey,
		fetchWithTokenOdataUnwrapped,
	);


	return { ...swrResponse };
};
