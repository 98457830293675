import { mergeStyleSets } from "@fluentui/react";

export const applicationSwichterStyles = mergeStyleSets({
    appsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    button: {
        '.ms-Icon': {
            fill: "rgb(16, 110, 190)",
        
            width: "17px",
            height: "17px"
        }
    }
})