import {
	CompoundButton,
	DefaultButton,
	FontIcon,
	PrimaryButton,
} from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { sqauredButtonStyle } from "./SquaredButton.styles";
import { SquaredButtonProps } from "./SquaredButton.types";

export const SquaredButton = ({
	iconName,
	...props
}: SquaredButtonProps) => {
	return (
		<CompoundButton
			primary
			className={sqauredButtonStyle}
			iconProps={{
				iconName,
			}}
			{...props}
		/>
	);
};
