import { SVGProps } from "react";

export const IssueLogIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
            viewBox="0 0 61 60"
			xmlns="http://www.w3.org/2000/svg"
			overflow="hidden"
			{...props}
		>
			<defs>
				<clipPath id="a">
					<path d="M422 465h61v60h-61z" />
				</clipPath>
			</defs>
			<g clipPath="url(#a)" transform="translate(-422 -465)">
				<path d="M454.724 475.313c.013-1.19-.951-2.167-2.16-2.188h-.064c-1.228 0-2.224.98-2.224 2.188 0 1.208.996 2.187 2.224 2.187 1.228 0 2.224-.98 2.224-2.188zm-1.27-.011a.945.945 0 01-.943.948.946.946 0 01-.964-.927.945.945 0 01.942-.948h.052a.92.92 0 01.912.927zm11.726 11.388l-.898-.883-4.634 4.557-2.093-2.057-.898.884 2.99 2.942 5.534-5.442zm-25.389 1.434h13.344v1.25H439.79zm25.389 6.068l-.898-.884-4.634 4.558-2.093-2.058-.898.884 2.99 2.942 5.534-5.442zm-25.389 1.433h13.344v1.25H439.79zm25.389 6.067l-.898-.884-4.634 4.558-2.093-2.058-.898.884 2.99 2.942 5.534-5.442zm-25.389 1.433h13.344v1.25H439.79zm25.389 6.067l-.898-.884-4.634 4.558-2.093-2.058-.898.884 2.99 2.942 5.534-5.442zm-25.389 1.433h13.344v1.25H439.79z" />
				<path d="M469.021 474.362h-9.531v-1.25c-.002-1.725-1.424-3.123-3.177-3.125h-7.625c-1.754.002-3.175 1.4-3.178 3.125v1.25h-9.53c-1.405 0-2.542 1.12-2.542 2.5V517.5c0 1.38 1.137 2.5 2.541 2.5h33.042c1.404 0 2.542-1.12 2.542-2.5v-40.638c0-1.38-1.138-2.5-2.542-2.5zm-24.781 1.25h2.541v-2.5c0-1.036.854-1.875 1.907-1.875h7.625c1.052 0 1.906.84 1.906 1.875v2.5h2.542v5.013H444.24zm26.052 41.888c0 .69-.57 1.25-1.271 1.25h-33.042a1.26 1.26 0 01-1.27-1.25v-40.638c0-.69.568-1.25 1.27-1.25h6.99v6.263h19.062v-6.263h6.99c.702 0 1.27.56 1.27 1.25z" />
			</g>
		</svg>
	);
};
