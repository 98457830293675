import { PageLayoutBase } from "../common/components/layout/PageLayoutBase";

interface NotFoundPageProps {
	headerAppName: string
}

export const NotFoundPage = ({headerAppName}: NotFoundPageProps) => {

	return (
		<PageLayoutBase headerAppName={"Project not Found"}>
			<h1 style={{margin: "3rem 2cm"}}>Error - Project not Found</h1>
		</PageLayoutBase>
	);
};
