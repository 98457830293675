import * as React from "react";
import {
	IContextualMenuProps,
	IIconProps,
} from "@fluentui/react";
import {
	DefaultButton,
} from "@fluentui/react/lib/Button";
import { useCreateableQualityGates } from "./useCreateableQualityGates";

export type CreateQualityGatesCallback = (qualityGateTypeKey: string) => void;

export interface QualityGatesCreateButtonProps {
	company: string;
	onClickCreateQualityGate: CreateQualityGatesCallback;
	disabled?: boolean;
}

const BUTTON_ICON: IIconProps = { iconName: "Add" };

export const QualityGatesCreateButton: React.FunctionComponent<
	QualityGatesCreateButtonProps
> = (props) => {
	const { disabled, company, onClickCreateQualityGate } = props;

	const { data: creatableQualityGates } = useCreateableQualityGates(company);

	const menuProps: IContextualMenuProps = {
		items:
			creatableQualityGates?.map((cqg) => ({
				key: String(cqg.id),
				text: cqg.name,
				onClick: (_ev, item) => {
					item?.key && onClickCreateQualityGate(item.key);
				},
			})) ?? [],
		directionalHintFixed: true,
	};

	return (
		<DefaultButton
			text="Create"
			iconProps={BUTTON_ICON}
			menuProps={menuProps}
			// Optional callback to customize menu rendering
			// menuAs={_getMenu}
			// Optional callback to do other actions (besides opening the menu) on click
			// By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
			// Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
			// persistMenu={true}
			allowDisabledFocus
			disabled={disabled}
		/>
	);
};
