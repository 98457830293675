export const QG_TYPE_PLANNING_MODIFICATION_ID = 3;
export const QG_TYPE_DELIVERABLE_ID = 4;

export const QG_PARAMETER_DELIVERABLE_CATEGORIE_ID = 5;

export const QG_PARAMETER_TYPE_CHECKBOX_NAME = "checkbox";
export const QG_PARAMETER_TYPE_JSONTEXT_NAME = "jsontxt";
export const QG_PARAMETER_TYPE_DATE_NAME = "date";
export const QG_PARAMETER_TYPE_DROPDOWN_NAME = "dropdown";
export const QG_PARAMETER_TYPE_TEXTINPUT_NAME = "textinput";
export const QG_PARAMETER_TYPE_FILEPICKER_NAME = "filepicker";
export const QG_PARAMETER_TYPE_PEOPLEPICKER_NAME = "peoplepicker";
export const QG_PARAMETER_TYPE_SEPERATOR_NAME = "separator";

export const QG_PARAMETER_NAME_REVISION = "Revision No.";

export const QG_STATUS_OPEN_ID = 1;
export const QG_STATUS_CLOSED_ID = 2;