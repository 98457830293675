import axios from "axios";
import { graphConfig } from "../authConfig";
import { fetchWithTokenOdataUnwrapped } from "../util/fetchWithToken";
import { useGraphToken } from "./useGraphToken";

const PROJECTMANAGEMENT_CHANNEL_NAMES = [
  "00_Projektmanagement",
  "00_Projectmanagement",
];

export const useTeams = (teamId?: string) => {
  const { token } = useGraphToken();

  const sendChannelMessage = (channelId: string, message: any) => {
    if (!(token && teamId)) {
      throw new Error(
        "Cannot send channel message beacause the token or teamid is not set"
      );
    }

    axios
      .post(
        `${graphConfig.graphTeamsEndpoint}/${teamId}/channels/${channelId}/messages`,
        message,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => res.data);
  };

  const sendProjectmanagementMessage = async (message: any) => {
    if (!(token && teamId)) {
      throw new Error(
        "Cannot send channel message beacause the token or teamid is not set"
      );
    }

    const channels: microsoftgraph.Channel[] =
      await fetchWithTokenOdataUnwrapped([
        `${graphConfig.graphTeamsEndpoint}/${teamId}/channels`,
        token,
      ]);
    if (!channels) {
      throw new Error("Channel could not be requested");
    }

    const projectChannel = channels.find((c) =>
      PROJECTMANAGEMENT_CHANNEL_NAMES.includes(c.displayName ?? "")
    );

    if (!projectChannel?.id) {
      throw new Error("Projectmanagement channel not found in Teams.");
    }

    sendChannelMessage(projectChannel.id, message);
  };

  return {
    sendChannelMessage,
    sendProjectmanagementMessage,
  };
};
