import useSWR from "swr";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { fetchWithTokenOdataUnwrapped } from "../../common/util/fetchWithToken";
import { QualityGatesType } from "../types/QualityGatesType";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useCreateableQualityGates = (company: string) => {
    const allowedCompanies = [
        "ALL",
        company
    ]
    const url = `${API_BASE_URL}QualityGatesTypes?$filter=isCreateable eq true and company/companyCode in('${allowedCompanies.join("', '")}')&$expand=company`

    const { token } = useMsalToken()

    return useSWR<QualityGatesType[]>([url, token], fetchWithTokenOdataUnwrapped);
}