import * as React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import {
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	IGroup,
	SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
	ContextualMenu,
	DefaultButton,
	IComboBoxOption,
	IContextualMenuProps,
} from "@fluentui/react";
import { EditIssueDialog } from "../EditIssueDialog";
import { Issue } from "../../../common/types/Issue";
import { useMemo, useState } from "react";
import { useIssues } from "../../../common/hooks/useIssues";
import { InfoBar } from "../../../common/components/message-bars/InfoBar";
import { issueListStyles } from "./IssueList.styles";
import {
	getIssueListColumns,
	getRenderItemColumnFunction,
} from "./IssueList.columns";
import { getIssueListHeaderContextualMenuProps } from "./IssueList.context-menu";
import { getIssueEnumContentById } from "../../../common/util/getIssueEnumContentById";
import { useProjectSite } from "../../../common/hooks/useProjectSite";
import { useTeams } from "../../../common/hooks/useTeams";
import { getChannelMessagePayload } from "../../../common/templates/ChannelMessage";
import { dateTimeFormatter } from "../../../common/util/dateTimeFormatter";
import { ProjectInfo } from "../../../common/components/ProjectInfo";

export interface IssueListSortingSettings {
	sortBy?: keyof Issue;
	isSortedDescending?: boolean;
	groupBy?: keyof Issue;
}

interface Props {
	issueLogKey?: string;
}

export const IssueList = ({ issueLogKey }: Props) => {
	// The text that is used in filtering our issues
	const [filterText, setFilterText] = useState<string>();
	const {
		issues,
		error,
		saveIssue,
		issuePriorities,
		issueStatuses,
		issueTypeNavigations,
	} = useIssues(issueLogKey);

	const { site } = useProjectSite(issueLogKey);
	const { sendProjectmanagementMessage } = useTeams(site?.groupId);

	// ---- Context Menu
	const [contextMenuProps, setContextMenuProps] =
		useState<IContextualMenuProps>();

	// ---- Sorting
	const [sortingSettings, setSortingSettings] =
		useState<IssueListSortingSettings>();

	// ---- Column Setup
	const onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn,
	): void => {
		setSortingSettings((settings) => ({
			sortBy: column.key as keyof Issue,
			isSortedDescending:
				settings?.sortBy === column.key ? !settings.isSortedDescending : true,
		}));
	};

	const onColumnContextMenu = (
		column?: IColumn,
		ev?: React.MouseEvent<HTMLElement>,
	) => {
		if (!(ev && column)) {
			return;
		}
		// If we set this the context menu appers
		// we use the generator function and pass the setContextMenuProps function
		// itself so that the context menu can get disabled from itself
		setContextMenuProps(
			getIssueListHeaderContextualMenuProps(
				ev,
				column,
				setSortingSettings,
				setContextMenuProps,
			),
		);
	};

	const columns = useMemo(
		() =>
			getIssueListColumns(onColumnClick, onColumnContextMenu, sortingSettings),
		[sortingSettings],
	);
	// ------------

	// This is the current issue that we are editing
	// a number of -1 means we create a new issue and
	// if this is undefined we are not editing an issue
	const [selectedIssue, setSelectedIssue] = useState<
		Partial<Issue> | undefined
	>(undefined);

	// This array is contains our currently displayed issues
	const modifiedIssues: Issue[] = useMemo(() => {
		// Something went wrong, we do not render anything
		if (error === null || issues === undefined) {
			return [];
		}

		let modifiedItems = issues.slice(0);

		// Filter by our current text
		if (filterText) {
			modifiedItems = modifiedItems.filter((item) =>
				item.issueName?.toLowerCase().includes(filterText.toLowerCase()),
			);
		}

		const sortedColumnKey = sortingSettings?.sortBy;
		if (sortedColumnKey) {
			modifiedItems = modifiedItems.sort((a: Issue, b: Issue) =>
				sortingSettings?.isSortedDescending
					? (a[sortedColumnKey] ?? "")
							.toString()
							.localeCompare((b[sortedColumnKey] ?? "").toString())
					: (b[sortedColumnKey] ?? "")
							.toString()
							.localeCompare((a[sortedColumnKey] ?? "").toString()),
			);
		}

		return modifiedItems;
	}, [issues, filterText, sortingSettings]);

	const currentGroups = useMemo(() => {
		// Check if there is a group key set
		const issueKey = sortingSettings?.groupBy;
		// Check if we are currently grouping and that both keys are matching
		if (
			issueKey == null ||
			sortingSettings?.groupBy !== sortingSettings?.sortBy
		) {
			return;
		}

		// Generate the groups
		const groups: Record<string, IGroup> = {};

		// Build a record using the current value of the field and our group settings
		modifiedIssues.forEach(
			(issue, index) => {
				const sortValue: any = issue[issueKey];

				if (sortValue in groups) {
					groups[sortValue].count++;
				} else {
					let content;

					switch (issueKey) {
						case "issuePrio":
						case "issueStatus":
						case "issueType": {
							const collectionField = {
								issuePrio: "issuePriority",
								issueStatus: "issueStatus",
								issueType: "issueType",
							}[issueKey as "issuePrio" | "issueStatus" | "issueType"];

							const collection = {
								issuePrio: issuePriorities,
								issueStatus: issueStatuses,
								issueType: issueTypeNavigations,
							}[issueKey as "issuePrio" | "issueStatus" | "issueType"];
							// Typescript does not seem to like this but it works with any
							content = getIssueEnumContentById<any>(
								sortValue,
								collectionField,
								collection!,
							);
							break;
						}
						case "issueByDate":
						case "issueTargetDate": {
							content = dateTimeFormatter.format(new Date(sortValue));
							break;
						}
						default:
							content = sortValue;
					}

					groups[sortValue] = {
						key: `group${sortValue}`,
						name: content,
						startIndex: index,
						count: 1,
						level: 0,
					};
				}
			},
			[modifiedIssues],
		);

		return Object.entries(groups).flatMap((rec) => rec[1]);
	}, [modifiedIssues, columns, sortingSettings]);

	// We create this here because it seems like fluentui is not able
	// to load data into an Combobox later
	const options = useMemo<{
		issueTypeOptions: IComboBoxOption[];
		issuePrioOptions: IComboBoxOption[];
		issueStatusOptions: IComboBoxOption[];
	}>(() => {
		return {
			issueTypeOptions:
				issueTypeNavigations?.map((it) => ({
					key: it.id,
					text: it.issueType,
				})) ?? [],

			issuePrioOptions:
				issuePriorities?.map((it) => ({
					key: it.id,
					text: it.issuePriority,
				})) ?? [],

			issueStatusOptions:
				issueStatuses?.map((it) => ({
					key: it.id,
					text: it.issueStatus,
				})) ?? [],
		};
	}, [issueStatuses, issueTypeNavigations, issuePriorities]);

	const onRenderItemColumn = getRenderItemColumnFunction(
		issuePriorities ?? [],
		issueStatuses ?? [],
		issueTypeNavigations ?? [],
		setSelectedIssue,
	);

	return (
		<div className={issueListStyles.siteLayout}>
			<ProjectInfo projectId={issueLogKey} />

			<TextField
				className={`${issueListStyles.exampleChildClass} ${issueListStyles.textFieldStyles}`}
				label="Filter by Issue Name:"
				onChange={(event, val) => setFilterText(val ?? "")}
			/>

			<DefaultButton
				iconProps={{ iconName: "Add" }}
				onClick={() => setSelectedIssue({ issueLogKey })}
				text="Create"
			/>

			{modifiedIssues.length === 0 && (
				<InfoBar text="No issues found for this project. Please create one, if necessary." />
			)}

			{modifiedIssues.length !== 0 && (
				<div
					data-is-scrollable={true}
					className={issueListStyles.detailsListContainer}
				>
					<DetailsList
						items={modifiedIssues}
						className={issueListStyles.detailsList}
						columns={columns}
						setKey="set"
						// getKey={(item: { id: string }) => item.id}
						layoutMode={DetailsListLayoutMode.justified}
						// selection={this._selection}
						groups={currentGroups}
						selectionPreservedOnEmptyClick={true}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="select row"
						onItemInvoked={(item: Issue) => setSelectedIssue({ ...item })}
						onRenderItemColumn={onRenderItemColumn}
						selectionMode={SelectionMode.none}
					/>
				</div>
			)}
			{contextMenuProps && <ContextualMenu {...contextMenuProps} />}

			{selectedIssue !== undefined && (
				<EditIssueDialog
					key={selectedIssue.id ?? ""}
					initialIssue={selectedIssue}
					options={options}
					onSubmit={(issue: Issue, assigneeUser, reporterUser) => {
						// Close the view and save issue
						setSelectedIssue(undefined);
						saveIssue(issue);

						// Check if this is a new issue
						if (issue.id !== undefined) {
							return;
						}

						// Send channel message
						// This works because an empty string evaluates to false
						if(issue.issueTo) {
							const channelMessagePayload = getChannelMessagePayload(
								issue.issueName,
								assigneeUser?.displayName ?? "",
								assigneeUser?.id ?? "",
								reporterUser?.displayName ?? "",
								window.location.href,
							);
							sendProjectmanagementMessage(channelMessagePayload);
						}
					}}
					onCancel={() => setSelectedIssue(undefined)}
				/>
			)}
		</div>
	);
};
