import { useMsal } from "@azure/msal-react";
import { getTheme, IconButton, mergeStyles, PrimaryButton } from "@fluentui/react";

import { Icon } from "@fluentui/react";
import { loginRequest } from "../../authConfig";

interface Props {
	signIn: boolean;
}

const style = mergeStyles({
  marginLeft: "16px",
  color: getTheme().palette.white
});

/**
 * Renders a sign-out button
 */
export const SignInOutButton = ({ signIn }: Props) => {
	const { instance } = useMsal();

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
	};

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.log(e);
		});
	};

	const handleClick = () => {
		signIn ? handleLogin() : handleLogout();
	};

	const iconName = signIn ? "SignIn" : "SignOut";

	return (
		<IconButton
			className={style}
			onClick={handleClick}
			iconProps={{
				iconName,
			}}
		/>
	);
};
