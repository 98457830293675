import { ParameterDataJsonText } from "../../quality-gates/types/ParameterDataJsonText";

const objRegex = /\{.*?\}/g
export function parseProjectModificationData(data: string) {
    const parsedValues = data.match(objRegex)?.map(entry => {
        // JS is the worst like JSON.parse does not indicate a possible failure at all..
        // I put this here because it may happen that a string is invalid
        try {
            return JSON.parse(entry) as ParameterDataJsonText
        } catch (err) {
            console.error(`Parsing failed for entry ${entry} error: ${err}`);
            // Return an indiciator for invalid data
            return {
                "01-01-1900": ["invalid@example.com", "Invalid data found unable to parse", "01-01-1900"]
            } as ParameterDataJsonText
        }
    })
        .reduce((prev, current) => {
            return { ...prev, ...current }
        }, {})

    return parsedValues;
}