import useSWR from "swr";
import { useMsalToken } from "../../../common/hooks/useMsalToken";
import { fetchWithToken } from "../../../common/util/fetchWithToken";
import { QualityGate } from "../../types/QualityGates";
import { QualityGatesType } from "../../types/QualityGatesType";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Fetch the Quality Gate Type using SWR
 * @param id the ID of the Quality Gate Type to fetch
 * @returns SWRResponse
 */
export const useQualityGateType = (id?: number) => {
    const qualityGatesFetchUrl = `${API_BASE_URL}QualityGatesTypes/${id}`;

    
	const { token } = useMsalToken();
    // If the id is null we will not let SWR fetch data
    const fetchKey = (id && token) ? [qualityGatesFetchUrl, token] : null
	return useSWR<QualityGatesType>(fetchKey, fetchWithToken);
}