import { createBrowserRouter } from "react-router-dom";
import { DashboardPage } from "../dashboard/DashboardPage";
import { QualityGates } from "../quality-gates/QualityGatesPage";
import { QualityGatesModal } from "../quality-gates/quality-gates-modal/QualityGatesModal";
import { NotFoundPage } from "../not-found/NotFoundPage";
import { IssueListPage } from "../issue-log/IssuesPage";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <NotFoundPage headerAppName="Not found" />,
	},
	{ path: "/issues", element: <NotFoundPage headerAppName="Issue List" /> },
	{
		path: "/qualitygates",
		element: <NotFoundPage headerAppName="Quality Gates" />,
	},
	{
		path: "/issues/:projectKey",
		element: <IssueListPage />,
	},
	{
		path: "/qualitygates/:projectKey",
		element: <QualityGates />,
		children: [
			{
				path: "edit/:qualityGateId",
				element: <QualityGatesModal />
			},
			{
				path: "new/:qualityGateTypeId",
				element: <QualityGatesModal isNew/>
			}
		]
	},
	{
		path: "/:projectKey",
		element: <DashboardPage />
	  }
]);
