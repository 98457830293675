import { useIsAuthenticated } from "@azure/msal-react";
import { SignInOutButton } from "./SignInOutButton";
import {
	getTheme,
	IStackItemStyles,
	IStackStyles,
	mergeStyles,
	Stack,
} from "@fluentui/react";
import { ApplicationSwitcher } from "./ApplicationSwitcher";
import { ProfileContent } from "../ProfileContent";
import { ApplicationSwitcherApp } from "./ApplicationSwitcher.types";

// Styles definition
const stackStyles: IStackStyles = {
	root: {
		background: "#005AAF",
		padding: "0 1rem",
	},
};

const nameStackItem: IStackItemStyles = {
	root: {
		backgroundColor: "#005AAF",
		height: 50,
		color: "white",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		display: "flex",
		...getTheme().fonts.xLarge,
	},
};

const buttonStackItem: IStackItemStyles = {
	root: {
		backgroundColor: "#005AAF",
		height: 50,
		color: "white",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		display: "flex",
	},
};

// getTheme().palette.
const verticalSeperatorStyle = mergeStyles({
	borderLeft: `1px solid ${
		getTheme().palette.white
	}` /* Rand auf der linken Seite */,
	width: "1px" /* Breite statt Höhe */,
	height: "1.5rem",
	margin: "0 16px",
});

const apps: ApplicationSwitcherApp[] = [
	{
		titel: "Dashboard",
		iconProps: {
			iconName: "Home",
		},
		getUrl: (key) => `/${key}`,
	},
	{
		titel: "Issue Log",
		iconProps: {
			iconName: "issue-log",
		},
		getUrl: (key) => `/issues/${key}`,
	},
	{
		titel: "Quality Log",
		iconProps: {
			iconName: "quality-gates",
		},
		getUrl: (key) => `/qualitygates/${key}`,
	},
];

interface PageHeaderProps {
	appName?: string;
}

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageHeader = ({ appName }: PageHeaderProps) => {
	const isAuthenticated = useIsAuthenticated();

	return (
		<Stack horizontal horizontalAlign="space-between" styles={stackStyles}>
			<Stack.Item styles={nameStackItem}>
				<ApplicationSwitcher apps={apps} />
				Fichtner Project Hub <div className={verticalSeperatorStyle} />{" "}
				{appName}
			</Stack.Item>
			<Stack.Item align="auto" styles={buttonStackItem}>
				{isAuthenticated && <ProfileContent />}
				<SignInOutButton signIn={!isAuthenticated} />
			</Stack.Item>
		</Stack>
	);
};
