import { SVGProps } from "react";

export const ProjectReportingIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			overflow="hidden"
			viewBox="0 0 61 60"
			{...props}
		>
			<path
				d="m54.268 48.304-8.932-8.948c-1.367-1.37-3.676-1.329-5.388 0l-2.645-2.644c6.907-7.46 6.458-19.106-1.002-26.013-7.46-6.906-19.106-6.458-26.012 1.002-6.907 7.46-6.458 19.107 1.002 26.013 7.097 6.571 18.069 6.528 25.115-.097l2.643 2.644a4.704 4.704 0 0 0-1.002 2.475 3.582 3.582 0 0 0 .998 2.897l8.929 8.944a3.55 3.55 0 0 0 2.548 1.017 4.748 4.748 0 0 0 3.314-1.429 4.797 4.797 0 0 0 1.427-2.962 3.576 3.576 0 0 0-.995-2.899zm-42.564-36.29a17.14 17.14 0 0 1 15.169-4.765 16.562 16.562 0 0 1 6.636 2.716 17.147 17.147 0 0 1 7.475 14.181h-6.672a.635.635 0 0 0-.482.222l-3.019 3.522a.007.007 0 0 1-.009.002.002.002 0 0 1-.002-.002l-2.228-8.356a.635.635 0 0 0-1.207-.064l-4.644 12.01c-.002.003-.005.005-.008.004l-.005-.004-3.021-16.346a.635.635 0 0 0-1.228-.085l-3.032 9.097h-8.74a17.108 17.108 0 0 1 5.017-12.132zm2.637 26.434a17.14 17.14 0 0 1-7.602-13.031h9.146a.635.635 0 0 0 .603-.435l2.39-7.169c.001-.004.005-.006.008-.004.002 0 .004.002.004.004l2.982 16.123a.635.635 0 0 0 .566.517h.059a.636.636 0 0 0 .592-.406l4.751-12.285c.001-.004.005-.005.008-.004.002 0 .004.002.004.004l2.034 7.625a.635.635 0 0 0 1.096.249l3.623-4.219h6.327c-.692 9.446-8.912 16.542-18.358 15.849a17.306 17.306 0 0 1-1.744-.218 16.531 16.531 0 0 1-6.489-2.6zm38.601 14.815c-1.239 1.234-3.063 1.422-4.067.416l-8.931-8.946a2.331 2.331 0 0 1-.63-1.889 3.528 3.528 0 0 1 1.057-2.172c1.238-1.235 3.062-1.422 4.067-.416l8.931 8.946c.482.504.713 1.197.631 1.889a3.535 3.535 0 0 1-1.058 2.172z"
			/>
		</svg>
	);
};
