import { useParams } from "react-router";
import useSWR from "swr";

import { IssueList } from "./components/issue-list/IssueList";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsalToken } from "../common/hooks/useMsalToken";
import { mergeStyles, Spinner } from "@fluentui/react";
import { useAuth } from "../common/hooks/useAuth";
import { ErrorBar } from "../common/components/message-bars/ErrorBar";
import { fetchWithToken } from "../common/util/fetchWithToken";
import { useIssues } from "../common/hooks/useIssues";
import { PageLayoutWithAuthentication } from "../common/components/layout/PageLayoutWithAuthentication";

const styles = mergeStyles({
	// overflow: "scroll",
	position: "absolute",
	inset: 0,
	top: "50px",
});

export const IssueListPage = () => {
	const { projectKey: issueLogKey } = useParams();
	const { issues, error } = useIssues(issueLogKey);

	return (
		<PageLayoutWithAuthentication headerAppName={"Issue Log"} className={styles}>
			{/* Only in loading state  */}
			{!(issues || error ) && (
				<Spinner
					styles={{ root: { marginTop: "100px" } }}
					label="Loading..."
					ariaLive="assertive"
					labelPosition="top"
				/>
			)}

			{/* After we catched the error  */}
			{error && <ErrorBar text={error?.response?.data?.value} />}

			{issues && <IssueList issueLogKey={issueLogKey} />}
		</PageLayoutWithAuthentication>
	);
};
