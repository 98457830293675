import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import { AuthenticationExplanation } from "../AuthenticationExplanation";
import { useAuth } from "../../hooks/useAuth";
import { PageHeader } from "../header/PageHeader";
import { PageLayoutBase, PageLayoutBaseProps } from "./PageLayoutBase";

interface PageLayoutWithAuthenticationProps extends PageLayoutBaseProps {}

export const PageLayoutWithAuthentication = ({
	children,
	...props
}: PageLayoutWithAuthenticationProps) => {
	useAuth();

	return (
		<PageLayoutBase {...props}>
			<AuthenticatedTemplate>{children}</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<AuthenticationExplanation />
			</UnauthenticatedTemplate>
		</PageLayoutBase>
	);
};
