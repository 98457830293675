import { Image, mergeStyleSets } from "@fluentui/react";

const styles = mergeStyleSets({
	root: {
		margin: "0 50px",
		textAlign: "left",
	},
	stepList: {
		listStylePosition: "inside",
	},
});

export const AuthenticationExplanation = () => {
	return (
		<div className={styles.root}>
			<h5>
				Please sign-in to see the Project Hub, in case the Project Hub doesn't load,
				please open the Project Hub in a seperate tab.
			</h5>
			<ul className={styles.stepList}>
				<li>right click on the Project Hub tab</li>
				<li>click go to website</li>
			</ul>
			<Image src="/images/goToWebsite.png" alt="Explanation Image" />
		</div>
	);
};
