import { PageHeader } from "../header/PageHeader";

export interface PageLayoutBaseProps
	extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	headerAppName?: string;
}

export const PageLayoutBase = ({
	children,
	headerAppName,
	...props
}: PageLayoutBaseProps) => {
	return (
		<>
			<PageHeader appName={headerAppName} />

			<div {...props}>
				{children}
			</div>
		</>
	);
};
