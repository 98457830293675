import { SVGProps } from "react";

export const BusinessCentralIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			overflow="hidden"
			viewBox="0 0 61 60"
			{...props}
		>
			<g>
				<path d="M8.777 8.896v43.208h42.481v-1.27H10.027V8.896h-1.25z" />
				<path d="M41.904 47.034h6.875V41.95h-6.875zm1.25-3.813h4.375v2.542h-4.375zm-6.251-3.179h7.5v-5.083h-7.5zm1.25-3.812h5v2.541h-5zm3.126-8.264h-13.75v5.083h13.75zm-1.25 3.813h-11.25v-2.542h11.25zM21.278 26.06h21.875v-5.084H21.278zm1.25-3.813h19.375v2.541H22.528zm-8.75-3.179h13.125v-5.084H13.778zm1.25-3.813h10.625v2.542H15.028z" />
			</g>
		</svg>
	);
};
