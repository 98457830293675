import useSWR from "swr";
import { ProjectSite } from "../types/ProjectSite";
import { fetchWithToken } from "../util/fetchWithToken";
import { useMsalToken } from "./useMsalToken";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useProjectSite = (projectId?: string) => {
  const { token } = useMsalToken();

  const { data, error } = useSWR<ProjectSite, any>(
    !token || !projectId
      ? null
      : [`${BASE_URL}fgprojectsites?projectKey=${projectId}`, token],
    fetchWithToken
  );

  return { site: data, error };
};
