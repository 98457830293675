import ReactDOM from "react-dom";
import { mergeStyles, initializeIcons, registerIcons } from "@fluentui/react";
import reportWebVitals from "./reportWebVitals";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./common/authConfig";
import { RouterProvider } from "react-router-dom";
import { router } from "./common/router";
import { Providers } from "@microsoft/mgt-react";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { QualityGatesIcon } from "./common/components/icon/QualityGates";
import { ProjectReportingIcon } from "./common/components/icon/ProjectReporting";
import { IssueLogIcon } from "./common/components/icon/IssueLog";
import { BusinessCentralIcon } from "./common/components/icon/BusinessCentral";
import { PublicClientApplication } from "@azure/msal-browser";

initializeIcons();

registerIcons({
	icons: {
		"business-central": <BusinessCentralIcon />,
		"issue-log": <IssueLogIcon />,
		"quality-gates": <QualityGatesIcon />,
		"project-reporting": <ProjectReportingIcon />
	},
});

// Inject some global styles
mergeStyles({
	":global(body,html,#root)": {
		margin: 0,
		padding: 0,
	},
});

const pca = new PublicClientApplication(msalConfig);

// // Init MGT Provider
Providers.globalProvider = new Msal2Provider({ publicClientApplication: pca });

ReactDOM.render(
	<MsalProvider instance={pca}>
		<FluentProvider theme={teamsLightTheme} style={{ minHeight: "100vh" }}>
			<RouterProvider router={router} />
		</FluentProvider>
	</MsalProvider>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
