// https://localhost:7086/api/QualityGates?$expand=Type($expand=Company($select=CompanyCode),Phase),Status

import { IGroup } from "@fluentui/react";
import { useMemo } from "react";
import useSWR from "swr";
import { fetchWithTokenOdataUnwrapped } from "../../common/util/fetchWithToken";
import { useMsalToken } from "../../common/hooks/useMsalToken";
import { QualityGate } from "../types/QualityGates";
import { QualityGatesPhase } from "../types/QualityGatesPhase";
import { QualityGatesListItem } from "./QualityGatesList.types";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PHASES_FETCH_URL = `${API_BASE_URL}QualityGatesPhases`;

/**
 * Fetch all Quality Gates Phases
 * @returns array of {@link QualityGatesPhase}
 */
export const useQualityGatesPhases = () => {
	const { token } = useMsalToken();

	const fetchKey = token ? [PHASES_FETCH_URL, token] : null;
	return useSWR<QualityGatesPhase[]>(fetchKey, fetchWithTokenOdataUnwrapped);
};
