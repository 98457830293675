import {
  DetailsListLayoutMode,
  IDetailsListProps,
  IGroup,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { useNavigate } from "react-router";
import { qualityGateColumns } from "./QualityGates.columns";
import { qualityGatesListStyles } from "./QualityGatesList.styles";
import {
  QualityGatesListItem,
  QualityGatesListProps,
} from "./QualityGatesList.types";
import { renderItemColumn } from "./renderItemColumn";
import { useQualityGatesListItems } from "./useQualityGatesItems";
import { ErrorBar } from "../../common/components/message-bars/ErrorBar";
import { useState } from "react";

export const QualityGatesList = ({ projectKey }: QualityGatesListProps) => {
  const navigate = useNavigate();
  const [openGroups, setOpenGroups] = useState<string[]>([])
  const handleGroupCollapse = (group: IGroup) => {
    // the variable group alway has the old state.
    // Example currently collapsed -> we click -> group.isCollapsed is true
    if(!group.isCollapsed) {
      setOpenGroups(groups => (groups.filter(g => g !== group.key)))
    }
    setOpenGroups(groups => ([...groups, group.key]))
  }
  const { isValidating, data, error } = useQualityGatesListItems(projectKey, openGroups);

  const listItems = data?.listItems ?? [];
  const groups = data?.groups ?? [];

  const handleItemInvoked: IDetailsListProps["onItemInvoked"] = (
    item?: QualityGatesListItem
  ) => {
    if (item == null) return;

    navigate(`edit/${item.id}`);
  };

  if (error) {
    return <ErrorBar text={error?.response?.data?.value} />;
  }

  if (data?.listItems.length === 0) {
    return (
      <ErrorBar text="QualityLog for this project does not exist. If your Project-Teamsite was just created, please wait a few hours until the QualityLog has been provisioned. If this error message persists please contact the IT-Service." />
    );
  }

  return (
    <ShimmeredDetailsList
      items={listItems}
      groups={groups}
      columns={qualityGateColumns}
      setKey="set"
      layoutMode={DetailsListLayoutMode.justified}
      onItemInvoked={handleItemInvoked}
      groupProps={{
        headerProps: {
          onToggleCollapse: handleGroupCollapse
        }
      }}
      onRenderItemColumn={renderItemColumn}
      selectionPreservedOnEmptyClick={true}
      selectionMode={SelectionMode.none}
      className={qualityGatesListStyles.detailsList}
      enableShimmer={!data}
      shimmerLines={3}
    />
  );
};
