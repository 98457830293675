import { Label, mergeStyles } from "@fluentui/react";

const parameterLabelSymbolStyle = mergeStyles({
	
    color: "rgb(164, 38, 44)",
    paddingRight: "12px",
	
});


const ParameterLabel = ({
  label = "",
  required = false,
}: {
  label?: string;
  required?: boolean;
}) => {
  return (
    <Label>
      {label}
      {required && <span className={parameterLabelSymbolStyle}> *</span>}
    </Label>
  );
};

export default ParameterLabel;
